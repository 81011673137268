
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { ArrowCircleRightIcon } from '@heroicons/vue/outline';
import Headroom from 'headroom.js';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import BorderButton from '@/components/shared/BorderButton.vue';
import SidebarLink from './SidebarLink.vue';
import NavLink from './NavLink.vue';
import CartIcon from '@/components/cart/CartIcon.vue';
import BusinessHoursLink from '@/components/shared/BusinessHoursLink.vue';
import useAuth from '@/hooks/auth/use-auth';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: {
    Skeleton,
    BorderButton,
    SidebarLink,
    NavLink,
    CartIcon,
    BusinessHoursLink,
    ArrowCircleRightIcon,
  },
  setup() {
    const state = reactive({
      open: false,
    });
    const { openAuthModal, isSignedIn, signOut } = useAuth();
    const { loadingUser } = useUser();

    const signOutUser = () => {
      signOut();
      window.location.replace('/');
    };

    onMounted(() => {
      const header = document.getElementById('header');

      if (!header) return;

      const options = { offset: header.clientHeight - 10, tolerance: 5 };
      const headroom = new Headroom(header, options);
      headroom.init();
    });

    return {
      ...toRefs(state),
      links: [
        {
          text: 'Accueil',
          href: '/',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
</svg>`,
        },
        {
          text: 'Choisir sa Pizza',
          href: '/boutique',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
</svg>`,
        },
        {
          text: 'Contact',
          href: '/contact',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20" />
</svg>`,
        },
      ],
      openAuthModal,
      loadingUser,
      isSignedIn,
      signOutUser,
    };
  },
});
