<template>
  <article
    v-if="variant === 'small'"
    class="h-80 relative rounded-2xl shadow-md overflow-hidden sm:h-96"
  >
    <div class="h-3/5 overflow-hidden relative">
      <img
        :src="offer.image?.url"
        width="340"
        height="220"
        :alt="offer.name"
        class="
          w-full
          h-full
          object-cover
          transition-transform
          transform
          duration-500
          hover:scale-110
        "
      />
    </div>
    <div class="h-2/5 p-4 flex flex-col">
      <h3 class="text-xl line-clamp-1 font-heading sm:text-2xl" :title="offer.name">
        {{ offer.name }}
      </h3>
      <div class="mt-auto flex justify-between items-center">
        <p class="text-3xl font-display relative text-gray-900">
          {{ offer.formattedPrice }}
        </p>
        <div>
          <AppRoundButton class="bg-primary hover:bg-white" label="Ajouter au panier">
            <PlusIcon
              class="h-6 w-6 text-white transition-colors group-hover:text-gray-800"
              @click="openModal"
            />
          </AppRoundButton>
        </div>
      </div>
    </div>

    <OfferProductsModal ref="offerProductsModal" :offer="offer" />
  </article>

  <article
    v-else
    class="
      w-full
      h-full
      flex flex-col
      rounded-tr-3xl rounded-br-3xl
      shadow-md
      md:rounded-tr-none md:flex-row
      bg-primary bg-gradient-to-tr
      from-red-500
      to-primary
    "
  >
    <div class="h-96 overflow-hidden rounded-tr-3xl md:flex-1 md:h-auto md:rounded-tr-none">
      <img
        :src="offer.image?.url"
        width="340"
        height="220"
        :alt="offer.name"
        class="
          w-full
          h-full
          object-cover
          transition-transform
          transform
          duration-500
          hover:scale-110
        "
      />
    </div>
    <div class="p-4 pb-0 flex flex-col justify-center md:flex-1 md:p-8">
      <header class="flex items-center justify-between space-x-2">
        <h3
          class="
            text-2xl
            line-clamp-1
            font-display font-semibold
            text-white
            sm:text-3xl
            md:text-4xl
          "
          :title="offer.name"
        >
          {{ offer.name }}
        </h3>
      </header>
      <div class="py-4 sm:py-8">
        <div class="space-y-2 sm:space-y-0 sm:space-x-20 sm:flex sm:items-center">
          <div class="flex flex-col space-y-4">
            <span class="font-heading text-white text-lg">Prix:</span>
            <p class="flex flex-col">
              <span class="text-2xl font-semibold text-gray-800 md:text-4xl">
                {{ offer.formattedPrice }}
              </span>
            </p>
          </div>
        </div>

        <div class="mt-8 space-y-4 md:space-y-0 md:space-x-6 flex flex-col md:flex-row">
          <AppButton
            class="
              space-x-2
              rounded-2xl
              bg-white
              hover:ring-4 hover:ring-red-200
              focus:outline-none focus:ring-4 focus:ring-red-400
            "
            @click="openModal"
          >
            <ShoppingBagIcon class="h-6 w-6" />
            <span class="ml-2">Ajouter au Panier</span>
          </AppButton>
        </div>
      </div>
    </div>

    <OfferProductsModal v-if="useInternalModal" ref="offerProductsModal" :offer="offer" />
  </article>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { PlusIcon, ShoppingBagIcon } from '@heroicons/vue/outline';

import { Offer } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import AppButton from '@/components/shared/AppButton.vue';
import OfferProductsModal from './OfferProductsModal.vue';

export default defineComponent({
  components: {
    AppRoundButton,
    PlusIcon,
    ShoppingBagIcon,
    OfferProductsModal,
    AppButton,
  },
  emits: ['on-open-modal'],
  props: {
    offer: {
      type: Object as PropType<Offer>,
      required: true,
    },
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'small',
    },
    useInternalModal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const offerProductsModal = ref<InstanceType<typeof OfferProductsModal>>();

    const openModal = () => {
      if (props.useInternalModal) {
        offerProductsModal.value?.popup?.open();
      } else {
        emit('on-open-modal', props.offer);
      }
    };

    return {
      offerProductsModal,
      openModal,
    };
  },
});
</script>
