import { reactive, toRefs, ToRefs } from 'vue';

import { Category, Product } from '@/types/models';
import { useGetCategories, UseGetCategories } from './categories-get';
import { useGetProductsByCategory, UseGetProductsByCategory } from './products-by-category';

const state: State = reactive({
  productsByCategory: {},
  categories: [],
  fetchingCategories: false,
});

export const getCategoriesState = (): State => state;

const useCategories: UseCategories = () => ({
  ...toRefs(state),
  useGetCategories,
  useGetProductsByCategory,
});

export default useCategories;

export function createCategory(name: string, slug: string, id: number): Category {
  return {
    id: id + 1,
    name,
    slug,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };
}

type State = {
  productsByCategory: Record<string, Product[]>;
  categories: Category[];
  fetchingCategories: boolean;
};

type UseCategories = () => ToRefs<State> & {
  useGetCategories: UseGetCategories;
  useGetProductsByCategory: UseGetProductsByCategory;
};
