import { Ref } from 'vue';

import { Product } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import formatProduct from '@/lib/formatProduct';
import { getCategoriesState } from './use-categories';

export const useGetProductsByCategory: UseGetProductsByCategory = () => {
  const state = getCategoriesState();
  const { errors, loading, request } = useRequest();

  const getProductsByCategory = async (
    categoryName: string,
    opts: Options = { limit: 9, sort: 'created_at:desc' }
  ) => {
    if (opts.limit === undefined) {
      opts.limit = 9;
    }

    if (opts.sort === undefined) {
      opts.sort = 'created_at:desc';
    }

    const cacheKey = `categories:${categoryName}:${opts.sort}`;

    if (state.productsByCategory[cacheKey]) return state.productsByCategory[cacheKey];

    let products: Product[] = [];

    const params = `_sort=${opts.sort}&_limit=${opts.limit}&categories.name_contains=${categoryName}`;
    const data = await request<Product[]>({ url: `/products?${params}` });

    if (data) {
      state.productsByCategory[cacheKey] = products = data.map(formatProduct);
    }

    return products;
  };

  return { getProductsByCategory, loading, errors };
};

export type UseGetProductsByCategory = () => {
  getProductsByCategory(categoryName: string, opts?: Options): Promise<Product[]>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type Options = {
  sort?: 'purchases:desc' | 'created_at:desc';
  limit?: number;
};
