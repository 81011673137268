<template>
  <section class="my-14 md:my-24">
    <div class="px-4 py-8 rounded-3xl bg-accent md:h-80 md:p-8 md:flex md:items-center">
      <div class="md:w-3/5">
        <h2 class="text-2xl font-heading sm:text-3xl md:text-4xl lg:text-5xl">
          Abonnez-vous à notre Newsletter
        </h2>
        <p class="mt-2 text-sm text-accent-1 sm:text-base md:w-5/6 md:mt-4 md:text-lg lg:text-xl">
          Inscrivez-vous à notre newsletter pour rester au courant de nos dernières promotions et de
          nos nouveaux recettes.
        </p>
      </div>
      <form class="md:w-2/5 mt-4 md:pl-4 md:mt-0 flex flex-col lg:pl-12" @submit.prevent="onSubmit">
        <div class="flex">
          <AppInput
            id="first_name"
            label="Prénom"
            class="flex-1"
            border="rounded-tl-xl rounded-bl-xl"
            v-model="newsLetterData.first_name"
            :errors="errors"
          />
          <AppInput
            id="last_name"
            label="Nom"
            class="flex-1"
            border="rounded-br-xl rounded-tr-xl"
            v-model="newsLetterData.last_name"
            :errors="errors"
          />
        </div>
        <AppInput
          id="email"
          type="email"
          label="Votre adresse e-mail:"
          class="mt-2"
          v-model="newsLetterData.email"
          :errors="errors"
        />
        <AppButton
          class="
            text-lg
            mt-4
            rounded-xl
            bg-primary
            text-white
            hover:bg-accent-1
            transition-colors
            sm:w-3/6
          "
          :loading="loading"
        >
          S'abonner
        </AppButton>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import { useRequest } from '@/lib/http';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppInput, AppButton },
  setup() {
    const { showToast } = useToast();
    const { user } = useUser();
    const { loading, errors, request } = useRequest();
    const newsLetterData = ref({
      first_name: user.value ? user.value.first_name : '',
      last_name: user.value ? user.value.last_name : '',
      email: '',
    });

    const onSubmit = async (e: Event) => {
      await request({
        url: `/newsletter-subscriptions`,
        method: 'POST',
        data: newsLetterData.value,
      });

      if (errors.value.length) {
        errors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      } else {
        showToast({ message: 'Vous vous êtes inscrit avec succès à notre newsletter' });
        (e.target as HTMLFormElement).reset();
      }
    };

    return {
      newsLetterData,
      loading,
      errors,
      onSubmit,
    };
  },
});
</script>
