
import { defineComponent, PropType, ref } from 'vue';

import { Product, ProductSize, Supplement } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import PopupModal from '@/components/shared/PopupModal.vue';
import ProductSupplements from './ProductSupplements.vue';
import ProductQuantityInput from './ProductQuantityInput.vue';
import ProductSizeInput from './ProductSizeInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useCart from '@/hooks/cart/use-cart';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    PopupModal,
    ProductSupplements,
    ProductQuantityInput,
    ProductSizeInput,
    AppButton,
    DisplayError,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const size = ref<ProductSize>();
    const quantity = ref(1);
    const selectedSupplements = ref<Supplement[]>([]);
    const clientErrors = ref<ResponseError[]>([]);
    const { showToast } = useToast();
    const { addCartProduct, loading, errors } = useCart().useAddCartProduct();

    const clearSelections = () => {
      size.value = undefined;
      quantity.value = 1;
      selectedSupplements.value = [];

      if (popup.value?.show) {
        popup.value?.close();
      }
    };

    const onDone = async () => {
      const newSize = size.value?.name
        ? size.value
        : {
            name: 'NORMALE',
            price: props.product.price,
            formattedPrice: props.product.formattedPrice,
          };

      if (props.product.variations && !size.value) {
        const message = 'Veuillez choisir une taille';
        clientErrors.value = [{ id: 'client.error.size', message }];
        return showToast({ message, type: 'failure' });
      }

      if (props.product.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
        return;
      }

      await addCartProduct({
        product: props.product,
        quantity: quantity.value,
        size: newSize,
        supplements: selectedSupplements.value,
      });

      if (!errors.value.length) {
        showToast({
          message: `${props.product.name} ajoutée au panier.`,
          type: 'link',
          link: '/checkout',
          linkText: 'Finaliser votre commande',
        });

        clientErrors.value = [];
        popup.value?.close();
      } else {
        clientErrors.value = [];
        errors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    return {
      popup,
      size,
      quantity,
      selectedSupplements,
      loading,
      errors,
      clientErrors,
      onDone,
      getLowestPrice,
      clearSelections,
    };
  },
});
