import { Ref } from 'vue';

import { Wishlist } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import formatWishlist from '@/lib/formatWishlist';
import { getWishlistState } from './use-wishlist';

export const useGetWishlist: UseGetWishlist = () => {
  const state = getWishlistState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const getWishlist = async () => {
    if (state.wishlist || state.fetchingWishlist) return;

    state.fetchingWishlist = true;

    const data = await request<Wishlist>({ url: `/wishlists` });

    if (data) {
      state.wishlist = formatWishlist(data);
    }

    state.fetchingWishlist = false;
  };

  return { loading, errors, getWishlist };
};

export type UseGetWishlist = () => {
  getWishlist(): void;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
