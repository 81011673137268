<template>
  <PopupModal ref="popup">
    <div class="p-4 round rounded-lg shadow-lg bg-white">
      <p class="text-xl font-display">Êtes-vous sûr?</p>
      <div class="mt-4 space-x-4">
        <button
          class="
            w-24
            p-2
            border
            rounded-lg
            transition-colors
            hover:bg-accent
            focus:outline-none focus:ring-4 focus:ring-gray-400
          "
          @click="popup?.close"
        >
          Annuler
        </button>
        <button
          class="
            w-24
            p-2
            rounded-lg
            bg-red-400
            text-white
            transition-colors
            hover:bg-red-600
            focus:outline-none focus:ring-4 focus:ring-red-600
          "
          @click="onConfirm"
        >
          Confirmer
        </button>
      </div>
    </div>
  </PopupModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import PopupModal from './PopupModal.vue';

export default defineComponent({
  components: { PopupModal },
  emits: ['on-confirm'],
  setup(_, { emit }) {
    const popup = ref<InstanceType<typeof PopupModal>>();

    const onConfirm = () => {
      emit('on-confirm');
      popup.value?.close();
    };

    return {
      popup,
      onConfirm,
    };
  },
});
</script>
