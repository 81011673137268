<template>
  <div class="flex flex-col justify-center">
    <h3 class="text-4xl font-heading text-center">Se connecter</h3>
    <DisplayError :errors="errors" class="text-center mt-3" />

    <form class="mt-6 space-y-4" @submit.prevent="onSubmit">
      <AppInput id="email" label="E-mail" />
      <div class="relative">
        <AppInput id="password" type="password" label="Mot de passe" />
        <button
          type="button"
          class="
            absolute
            top-0
            right-0
            text-sm text-gray-500
            transition-transform
            hover:scale-105
            focus:outline-none focus:scale-105
          "
          @click="changeAuthAction('resetPassword')"
        >
          Mot de passe oublié?
        </button>
      </div>
      <AppButton
        class="w-full bg-primary text-white hover:ring-red-200 focus:ring-red-200"
        :loading="loading || captchaLoading"
        type="submit"
      >
        Se connecter
      </AppButton>
    </form>
    <div class="mt-6 space-x-1 flex items-center justify-center">
      <p class="text-gray-600">Vous n'avez pas de compte?</p>
      <button
        class="
          text-primary
          font-medium
          cursor-pointer
          transition-transform
          hover:scale-105
          focus:outline-none focus:scale-105
        "
        @click="changeAuthAction('signUp')"
      >
        S’inscrire
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import AppButton from '@/components/shared/AppButton.vue';
import useAuth from '@/hooks/auth/use-auth';
import useCart from '@/hooks/cart/use-cart';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { AppInput, DisplayError, AppButton },
  props: {
    getRecaptchaToken: {
      type: Function,
      required: true,
    },
    captchaLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { changeAuthAction, closeAuthModal, useSignIn, afterAuthAction } = useAuth();
    const { signIn, loading, errors } = useSignIn();
    const { useGetCartItems, itemsSynced } = useCart();
    const { getCartItems } = useGetCartItems();
    const { showToast } = useToast();

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const identifier = (elements.namedItem('email') as HTMLInputElement).value;
      const password = (elements.namedItem('password') as HTMLInputElement).value;

      const captchaToken = await props.getRecaptchaToken();
      if (!captchaToken) return;

      await signIn({ identifier, password, captchaToken });

      if (!errors.value.length) {
        closeAuthModal();
        (e.target as HTMLFormElement).reset();

        await getCartItems();

        if (itemsSynced.value) {
          const message =
            'Vous avez laissé des articles dans votre panier la dernière fois que vous étiez ici, nous les avons eu pour vous !';
          showToast({ message }, -1);
        }

        if (afterAuthAction.value.action) {
          afterAuthAction.value.action();
        }
      }
    };

    return {
      changeAuthAction,
      errors,
      loading,
      onSubmit,
    };
  },
});
</script>
