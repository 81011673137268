<template>
  <section class="mb-14 flex">
    <Skeleton
      :show="loading || offersLoading"
      class="
        w-10/12
        h-[680px]
        overflow-hidden
        rounded-tr-3xl rounded-br-3xl
        shadow-md
        md:w-11/12 md:rounded-tr-none md:h-[600px]
      "
    >
      <swiper
        direction="vertical"
        :navigation="navigation"
        :autoplay="autoplay"
        :loop="true"
        touchReleaseOnEdges
        class="w-full h-full z-20"
        v-if="!errors.length || !offerErrors.length"
      >
        <swiper-slide v-for="offer in featuredOffers" :key="offer.id" class="flex">
          <OfferCard
            variant="big"
            :offer="offer"
            :useInternalModal="false"
            @on-open-modal="openOfferModal"
          />
        </swiper-slide>
        <OfferProductsModal ref="offerProductsModal" :offer="modalOffer || {}" />

        <swiper-slide v-for="product in featuredProducts" :key="product.id" class="flex">
          <ProductCard
            :product="product"
            :useInternalModal="false"
            @on-open-modal="openProductModal"
          />
        </swiper-slide>
        <ProductOptionsModal ref="productOptionsModal" :product="modalProduct || {}" />
      </swiper>

      <div v-else class="w-full h-full p-4 flex items-center justify-center">
        <div v-for="error in errors" :key="error.id">
          <p class="text-2xl text-red-400">
            {{ error.message }}
          </p>
        </div>
        <div v-for="error in offerErrors" :key="error.id">
          <p class="text-2xl text-red-400">
            {{ error.message }}
          </p>
        </div>
      </div>

      <template #fallback>
        <ProductCardSkeleton />
      </template>
    </Skeleton>

    <div class="w-2/12 mt-8 px-2 flex flex-col items-center space-y-4 md:w-1/12">
      <button id="swiper-button-prev" class="w-8 h-8 sm:w-12 sm:h-12 rounded-full text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-full w-full"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
          />
        </svg>
      </button>
      <button id="swiper-button-next" class="w-8 h-8 sm:w-12 sm:h-12 rounded-full text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-full w-full"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
          />
        </svg>
      </button>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, A11y, Autoplay } from 'swiper/core';
import 'swiper/swiper.scss';

import { Offer, Product } from '@/types/models';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import ProductCardSkeleton from '@/components/skeleton/ProductCardSkeleton.vue';
import ProductCard from '@/components/product/ProductCard.vue';
import ProductOptionsModal from '@/components/product/ProductOptionsModal.vue';
import OfferCard from '@/components/offers/OfferCard.vue';
import useProducts from '@/hooks/products/use-products';
import useOffers from '@/hooks/offers/use-offers';
import OfferProductsModal from '../offers/OfferProductsModal.vue';

SwiperCore.use([Navigation, Autoplay, A11y]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    ProductCard,
    Skeleton,
    ProductCardSkeleton,
    ProductOptionsModal,
    OfferCard,
    OfferProductsModal,
  },
  setup() {
    const { featuredProducts, useFeaturedProducts } = useProducts();
    const { getFeaturedProducts, loading, errors } = useFeaturedProducts();
    const { featuredOffers, useFeaturedOffers } = useOffers();
    const { getFeaturedOffers, loading: offersLoading, errors: offerErrors } = useFeaturedOffers();
    const modalProduct = ref<Product>();
    const modalOffer = ref<Offer>();
    const productOptionsModal = ref<InstanceType<typeof ProductOptionsModal>>();
    const offerProductsModal = ref<InstanceType<typeof OfferProductsModal>>();

    const openProductModal = (product: Product) => {
      modalProduct.value = product;
      productOptionsModal.value?.popup?.open();
    };

    const openOfferModal = (offer: Offer) => {
      modalOffer.value = offer;
      offerProductsModal.value?.popup?.open();
    };

    const getFeaturedItems = async () => {
      await Promise.all([getFeaturedOffers(), getFeaturedProducts()]);
    };

    getFeaturedItems();

    return {
      openProductModal,
      openOfferModal,
      productOptionsModal,
      offerProductsModal,
      modalProduct,
      modalOffer,
      featuredProducts,
      loading,
      errors,
      featuredOffers,
      offersLoading,
      offerErrors,
      navigation: {
        nextEl: '#swiper-button-next',
        prevEl: '#swiper-button-prev',
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    };
  },
});
</script>
