import { BusinessHours } from '@/types/models';
import { defaultlocale } from './constants';

export function getWorkingHours(businessHours: BusinessHours): string {
  const openTime = getHoursAndMinutes(businessHours.open_time);
  const closeTime = getHoursAndMinutes(businessHours.close_time);

  if (!businessHours.break_start || !businessHours.break_end) {
    return `Nous sommes ouverts aujourd'hui de ${openTime} à ${closeTime}`;
  }

  const breakStart = getHoursAndMinutes(businessHours.break_start);
  const breakEnd = getHoursAndMinutes(businessHours.break_end);

  if (breakStart === '00:00' || breakEnd === '00:00') {
    return `Nous sommes ouverts aujourd'hui de ${openTime} à ${closeTime}`;
  }

  return `Nous sommes ouverts de aujourd'hui ${openTime} à ${breakStart} et de ${breakEnd} à ${closeTime}`;
}

export function isWithinBusinessHours(businessHours: BusinessHours): boolean {
  const today = Date.now();
  const openTime = generateValidDateFromTime(businessHours.open_time).getTime();
  const closeTime = generateValidDateFromTime(businessHours.close_time).getTime();
  const withinBusinessHours = today >= openTime && today < closeTime;

  return withinBusinessHours && !isWithinBreaktime(businessHours);
}

function isWithinBreaktime(businessHours: BusinessHours) {
  // Assume working full day if we don't have a break start/end
  if (!businessHours.break_start || !businessHours.break_end) return false;

  const today = Date.now();
  const breakStart = generateValidDateFromTime(businessHours.break_start).getTime();
  const breakEnd = generateValidDateFromTime(businessHours.break_end).getTime();
  const withinBreaktime = today >= breakStart && today < breakEnd;

  return withinBreaktime;
}

function getHoursAndMinutes(time: string) {
  const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
  return generateValidDateFromTime(time).toLocaleTimeString(defaultlocale, options);
}

function generateValidDateFromTime(time: string) {
  return new Date(`${getCurrentDate()} ${time}`);
}

function getCurrentDate() {
  const now = new Date();
  const month = now.getUTCMonth() + 1; // months from 1-12
  const day = now.getUTCDate();
  const year = now.getUTCFullYear();

  return `${year}/${month}/${day}`;
}
