<template>
  <router-link
    :to="{ name: 'Contact', hash: '#HeuresDouverture' }"
    class="flex items-center text-gray-500 group hover:text-gray-800"
    :title="currentlyOpen ? 'Étaient ouverts' : 'Désolé, nous sommes fermés'"
  >
    Horaires de travail
    <span
      class="inline-block ml-2 w-2 h-2 rounded-full border group-hover:border-gray-600"
      :class="[currentlyOpen ? 'bg-green-400 border-green-400' : 'bg-red-400 border-red-400']"
      :aria-label="currentlyOpen ? 'Étaient ouverts' : 'Désolé, nous sommes fermés'"
    />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useBusinessHours from '@/hooks/business-hours/use-business-hours';

export default defineComponent({
  setup() {
    const { currentlyOpen, useGetBusinessHours } = useBusinessHours();
    const { getBusinessHours } = useGetBusinessHours();

    getBusinessHours();

    return {
      currentlyOpen,
    };
  },
});
</script>
