import { ref } from 'vue';

import type Toast from '@/components/shared/Toast.vue';

export type ToastType = 'success' | 'failure' | 'link';
export type Toast = {
  id?: number;
  message: string;
  type?: ToastType;
  link?: string;
  linkText?: string;
};

const toast = ref();

export default function useToast(): Result {
  const showToast = (newToast: Toast, duration = 5000) => {
    if (toast.value) {
      if (!newToast.type) {
        newToast.type = 'success';
      }

      toast.value.addToast(newToast);
      toast.value.open(duration);
    }
  };

  return {
    toast,
    showToast,
  };
}

type Result = {
  toast: unknown; // TODO(karim): find a way to type this as ToastComponent
  showToast(toast: Toast, duration?: number): void;
};

export type ToastComponent = InstanceType<typeof Toast>;
