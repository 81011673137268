import { Offer, OfferItem } from '@/types/models';
import formatMoney from './formatMoney';
import formatProduct, { formatImage } from './formatProduct';

export function formatOffer(offer: Offer): Offer {
  return {
    ...offer,
    price: Number(offer.price),
    formattedPrice: formatMoney(Number(offer.price)),
    image: formatImage(offer.image),
  };
}

export function formatOfferItem(offerItem: OfferItem): OfferItem {
  return {
    ...offerItem,
    offer: formatOffer(offerItem.offer),
    products: offerItem.products.map(formatProduct),
  };
}
