import { reactive, toRefs, ToRefs } from 'vue';

import { Wishlist } from '@/types/models';
import { inWishlist } from './utils';
import { useGetWishlist, UseGetWishlist } from './wishlist-get';
import { useAddWishlistItem, UseAddWishlistItem } from './wishlist-add';
import { useRemoveWishlistItem, UseRemoveWishlistItem } from './wishlist-remove';

const state: State = reactive({
  wishlist: null,
  fetchingWishlist: false,
});

export const getWishlistState = (): State => state;

const useWishlist: UseWishlist = () => ({
  ...toRefs(state),
  useGetWishlist,
  useAddWishlistItem,
  useRemoveWishlistItem,
  inWishlist,
});

export default useWishlist;

type State = {
  wishlist: Wishlist | null;
  fetchingWishlist: boolean;
};

type UseWishlist = () => ToRefs<State> & {
  useGetWishlist: UseGetWishlist;
  useAddWishlistItem: UseAddWishlistItem;
  useRemoveWishlistItem: UseRemoveWishlistItem;
  inWishlist(productId: number): boolean;
};
