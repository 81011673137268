
import { defineComponent, ref } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import useToast from '@/hooks/use-toast';
import useAuth from '@/hooks/auth/use-auth';
import useCart from '@/hooks/cart/use-cart';

export default defineComponent({
  components: { AppInput, AppButton, DisplayError, AppCheckbox },
  props: {
    getRecaptchaToken: {
      type: Function,
      required: true,
    },
    captchaLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { showToast } = useToast();
    const { changeAuthAction, closeAuthModal, useSignUp, afterAuthAction } = useAuth();
    const { signUp, loading, errors } = useSignUp();
    const { itemsSynced, useGetCartItems } = useCart();
    const { getCartItems } = useGetCartItems();
    const registerData = ref({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      newsletter_subscribe: true,
      agreed_to_terms: false,
      captchaToken: '',
    });

    const onSubmit = async (e: Event) => {
      const captchaToken = await props.getRecaptchaToken();

      if (!captchaToken) return;

      registerData.value.captchaToken = captchaToken;

      await signUp(registerData.value);

      if (!errors.value.length) {
        closeAuthModal();
        (e.target as HTMLFormElement).reset();

        await getCartItems();

        if (itemsSynced.value) {
          const message =
            'Vous avez laissé des articles dans votre panier la dernière fois que vous étiez ici, nous les avons eu pour vous !';
          showToast({ message }, -1);
        }

        if (afterAuthAction.value.action) {
          afterAuthAction.value.action();
        }
      }
    };

    return {
      registerData,
      errors,
      loading,
      changeAuthAction,
      onSubmit,
    };
  },
});
