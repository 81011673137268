import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import useCart from '@/hooks/cart/use-cart';
import useAuth from '@/hooks/auth/use-auth';
import useUser from '@/hooks/user/use-user';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/boutique',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue'),
  },
  {
    path: '/product/:slug',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue'),
    beforeEnter(_, from) {
      const { count } = useCart();
      const { isSignedIn, openAuthModal } = useAuth();

      if (count.value <= 0) {
        return from;
      }

      if (!isSignedIn.value) {
        openAuthModal('signIn', {
          action: () => {
            router.push('/checkout');
          },
        });
        return from;
      }
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    async beforeEnter(_, from) {
      const { fetchUser } = useUser();
      const fetched = await fetchUser();
      if (!fetched) return from;
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
    async beforeEnter(to, from) {
      const { isSignedIn } = useAuth();

      if (!to.query.code || isSignedIn.value) {
        return from;
      }
    },
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    component: () => import(/* webpackChunkName: "wishlist" */ '../views/Wishlist.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/mention-legales',
    name: 'Privacy-Policy',
    component: () => import(/* webpackChunkName: "mention-legales" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '/condition-general-de-vents',
    name: 'Standard-Form-Contract',
    component: () =>
      import(/* webpackChunkName: "standard-form-contract" */ '../views/StandardFormContract.vue'),
  },
  {
    path: '/:pathMatch(.*)*', // catch all route
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { el: to.hash };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
