<template>
  <div class="transition-all duration-700 ease-in">
    <article
      class="w-full flex flex-col overflow-hidden sm:flex-row bg-white shadow rounded-md sm:h-36"
    >
      <div class="w-full h-24 relative sm:w-2/5 sm:h-full">
        <img
          :src="item.product ? item.product.image?.url : item.offer_item?.offer?.image?.url"
          alt="pizza"
          width="120"
          height="120"
          class="w-full h-full object-cover"
        />
      </div>
      <div class="p-2 pb-3 flex-1 flex flex-col">
        <div class="flex justify-between items-center">
          <h5 class="font-display text-xl">
            {{ item.product ? item.product.name : item.offer_item?.offer?.name }}
          </h5>
          <button class="w-6 h-6 group focus:outline-none" @click="confirmBox?.popup?.open">
            <span class="sr-only">Supprimer l'article du panier</span>
            <XCircleIcon
              class="w-full h-full text-gray-400 group-hover:text-red-400 group-focus:text-red-400"
            />
          </button>
        </div>
        <p class="mt-1 mb-4">{{ sumCartItemPrice(item) }}</p>
        <AppButton
          v-if="item.product || item.offer_item"
          class="
            text-xs
            border-2
            text-gray-800
            hover:bg-gray-800 hover:text-white
            focus:outline-none focus:bg-gray-800 focus:text-white
            transition-colors
          "
          @click="openModal"
        >
          Modifier votre article
          <PencilIcon class="ml-2 w-4 h-4" />
        </AppButton>
      </div>
    </article>

    <ConfirmationBox ref="confirmBox" @on-confirm="removeCartItem(item)" />
    <EditCartOptions ref="editCartOptions" :item="item" />
    <OfferProductsModal
      ref="offerProductsModal"
      v-if="item.offer_item"
      action="update"
      :defaultSelectedProducts="item.offer_item.products"
      :offer="item.offer_item.offer"
      :offerItemId="item.offer_item.id"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { XCircleIcon, PencilIcon } from '@heroicons/vue/solid';

import { CartItem } from '@/types/models';
import ConfirmationBox from '@/components/shared/ConfirmationBox.vue';
import useCart from '@/hooks/cart/use-cart';
import AppButton from '@/components/shared/AppButton.vue';
import EditCartOptions from './EditCartOptions.vue';
import OfferProductsModal from '@/components/offers/OfferProductsModal.vue';

export default defineComponent({
  components: {
    XCircleIcon,
    PencilIcon,
    ConfirmationBox,
    AppButton,
    EditCartOptions,
    OfferProductsModal,
  },
  emits: ['on-open-options-modal'],
  props: {
    item: {
      type: Object as PropType<CartItem>,
      required: true,
    },
    useInternalEditOptionsModal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const confirmBox = ref<InstanceType<typeof ConfirmationBox>>();
    const editCartOptions = ref<InstanceType<typeof EditCartOptions>>();
    const offerProductsModal = ref<InstanceType<typeof OfferProductsModal>>();

    const { useRemoveCartItem, sumCartItemPrice } = useCart();
    const { removeCartItem } = useRemoveCartItem();

    const openModal = () => {
      if (props.item.product) {
        if (props.useInternalEditOptionsModal) {
          editCartOptions.value?.popup?.open();
        } else {
          emit('on-open-options-modal', props.item);
        }
      }

      if (props.item.offer_item) {
        offerProductsModal.value?.popup?.open();
      }
    };

    return {
      confirmBox,
      editCartOptions,
      offerProductsModal,
      openModal,
      removeCartItem,
      sumCartItemPrice,
    };
  },
});
</script>
