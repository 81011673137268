/* eslint-disable @typescript-eslint/no-explicit-any */
type Ret = Record<string, any>;

export const globalMetaTags = {
  title: 'Miam Pizza',
  htmlAttrs: {
    lang: 'fr',
  },
  og: {
    type: 'website',
    locale: 'fr_FR',
    image: [require('@/assets/images/bg.jpg')],
  },
};

export const homeMetaTags = (): Ret => {
  const title = 'Accueil | Miam Pizza';
  const description = "Bienvenue à Miami Pizza, l'usine de Willy Wonka Pizza";

  return {
    title,
    description,
    og: {
      title,
      description,
    },
  };
};

export const productMetaTags = (): Ret => {
  const title = 'Pas trouvé';
  const description = "La page que vous recherchez n'a pas été trouvée";

  return {
    title,
    description,
    og: {
      title,
      description,
    },
  };
};

export const checkoutMetaTags = (): Ret => {
  const title = 'Vérifier | Miam Pizza';
  const description = 'Finalisez votre commande ici';

  return {
    title,
    description,
    og: {
      title,
      description,
    },
  };
};

export const accountMetaTags = (): Ret => {
  const title = 'Compte | Miam Pizza';
  const description = 'Votre page de compte, où vous pouvez modifier vos informations';

  return {
    title,
    description,
    og: {
      title,
      description,
    },
  };
};

export const resetPasswordMetaTags = (): Ret => {
  const title = 'Réinitialisation du mot de passe | Miam Pizza';
  const description =
    'Pour réinitialiser votre mot de passe, cliquez sur le bouton de connexion, puis sur mot de passe oublié et suivez les instructions';

  return {
    title,
    description,
    og: {
      title,
      description,
    },
  };
};
