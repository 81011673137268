import { reactive, ToRefs, toRefs } from 'vue';

import { Product } from '@/types/models';
import { useAllProducts, UseAllProducts } from './all-products';
import { useFeaturedProducts, UseFeaturedProducts } from './featured-products';
import { usePopularProducts, UsePopularProducts } from './popular-products';

const state: State = reactive({
  products: [],
  featuredProducts: [],
  popularProducts: [],
  count: undefined,
  filteredProductsCount: undefined,
});

export const getProductsState = (): State => state;

export default function useProducts(): Result {
  return {
    ...toRefs(state),
    useAllProducts,
    useFeaturedProducts,
    usePopularProducts,
  };
}

type State = {
  products: Product[];
  featuredProducts: Product[];
  popularProducts: Product[];
  count: number | undefined;
  filteredProductsCount: number | undefined;
};

type Result = ToRefs<State> & {
  useAllProducts: UseAllProducts;
  useFeaturedProducts: UseFeaturedProducts;
  usePopularProducts: UsePopularProducts;
};
