export default function formatMoney(amount = 0): string {
  const opts: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  };

  if (amount % 1 === 0) {
    opts.minimumFractionDigits = 0;
  }

  const formatter = Intl.NumberFormat('fr-FR', opts);

  return formatter.format(amount);
}
