import { Ref } from 'vue';

import { Product } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import formatProduct from '@/lib/formatProduct';
import { getProductsState } from './use-products';

export const usePopularProducts: UsePopularProducts = () => {
  const state = getProductsState();
  const { errors, loading, request } = useRequest();

  const getPopularProducts = async () => {
    if (state.popularProducts.length) return;

    const data = await request<Product[]>({ url: `/products?_sort=purchases:desc&_limit=9` });

    if (data) {
      state.popularProducts = data.map(formatProduct);
    }
  };

  return { loading, errors, getPopularProducts };
};

export type UsePopularProducts = () => {
  getPopularProducts(): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
