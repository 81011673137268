
import { defineComponent, PropType, ref } from 'vue';
import { ShoppingBagIcon, PlusIcon } from '@heroicons/vue/outline';
import { HeartIcon } from '@heroicons/vue/solid';

import { Product } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import AppButton from '@/components/shared/AppButton.vue';
import ProductOptionsModal from './ProductOptionsModal.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useWishlist from '@/hooks/wishlist/use-wishlist';
import useAuth from '@/hooks/auth/use-auth';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    AppRoundButton,
    ProductOptionsModal,
    AppButton,
    ShoppingBagIcon,
    PlusIcon,
    HeartIcon,
  },
  emits: ['on-open-modal'],
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'big',
    },
    useInternalModal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { isSignedIn, openAuthModal } = useAuth();
    const { useAddWishlistItem, inWishlist } = useWishlist();
    const { showToast } = useToast();
    const { addWishlistItem, loading, errors: wishlistErrors } = useAddWishlistItem();

    const productOptionsModal = ref<InstanceType<typeof ProductOptionsModal>>();

    const addToWishlist = async () => {
      if (!isSignedIn.value) {
        return openAuthModal('signIn', { action: addToWishlist });
      }

      await addWishlistItem(props.product);

      if (!wishlistErrors.value.length) {
        showToast({ message: `${props.product.name} ajouté à la liste de souhaits` });
      } else {
        wishlistErrors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    const openOptionsModal = () => {
      if (props.useInternalModal) {
        productOptionsModal.value?.popup?.open();
      } else {
        emit('on-open-modal', props.product);
      }
    };

    return {
      productOptionsModal,
      loading,
      openOptionsModal,
      addToWishlist,
      inWishlist,
      getLowestPrice,
    };
  },
});
