import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { CartItem } from '@/types/models';
import { useRequest } from '@/lib/http';
import { getFromLocalStorage, mergeCartItems, saveToLocalStorage, syncCartItems } from './utils';
import { getCartState } from './use-cart';
import formatCartItem from '@/lib/formatCartItem';
import useAuth from '@/hooks/auth/use-auth';

const { isSignedIn } = useAuth();

const useGetCartItems: UseGetCartItems = () => {
  const state = getCartState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const getLocalCartItems = (): CartItem[] => {
    return getFromLocalStorage();
  };

  const getRemoteCartItems = async (): Promise<CartItem[]> => {
    let cartItems: CartItem[] = [];

    const data = await request<CartItem[]>({ url: `/cart-items?_sort=created_at:DESC` });

    if (data) {
      cartItems = data.map(formatCartItem);
    }

    return cartItems;
  };

  const getCartItems = async () => {
    if (isSignedIn.value) {
      const localItems = getLocalCartItems();
      const remoteItems = await getRemoteCartItems();
      state.items = mergeCartItems(localItems, remoteItems);
      await syncCartItems();
    } else {
      state.items = getLocalCartItems();
    }

    saveToLocalStorage(state.items);
  };

  return { loading, errors, getCartItems };
};

export default useGetCartItems;

export type UseGetCartItems = () => {
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
  getCartItems(): Promise<void>;
};
