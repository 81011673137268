
import { defineComponent, PropType, ref, watch } from 'vue';
import { XIcon } from '@heroicons/vue/outline';

import { Offer, Product } from '@/types/models';
import PopupModal from '@/components/shared/PopupModal.vue';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import ProductListSkeleton from '@/components/skeleton/ProductListSkeleton.vue';
import OfferProductCard from './OfferProductCard.vue';
import Pagination from '@/components/shared/Pagination.vue';
import AppButton from '@/components/shared/AppButton.vue';
import useProducts from '@/hooks/products/use-products';
import useToast from '@/hooks/use-toast';
import useCart from '@/hooks/cart/use-cart';

export default defineComponent({
  components: {
    XIcon,
    PopupModal,
    AppButton,
    Skeleton,
    ProductListSkeleton,
    OfferProductCard,
    Pagination,
  },
  props: {
    offer: {
      type: Object as PropType<Offer>,
      required: true,
    },
    offerItemId: {
      type: Number,
      default: -1,
    },
    action: {
      type: String as PropType<'create' | 'update'>,
      default: 'create',
    },
    defaultSelectedProducts: {
      type: Array as PropType<Product[]>,
      default: () => [],
    },
  },
  setup(props) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const itemsPerPage = 12;
    const page = ref(1);
    const { showToast } = useToast();
    const { products, count, useAllProducts } = useProducts();
    const { getAllProducts, loading: productsLoading, errors: productErrors } = useAllProducts();
    const { useAddCartOffer, useUpdateCartOffer } = useCart();
    const { addCartOffer, loading: createLoading, errors: createErrors } = useAddCartOffer();
    const { updateCartOffer, loading: updateLoading, errors: updateErrors } = useUpdateCartOffer();
    const selectedProducts = ref<Product[]>([]);

    const paginateProducts = async (page: number) => {
      if (page >= 1) {
        const offset = page === 1 ? 0 : (page - 1) * itemsPerPage;

        await getAllProducts({
          start: offset,
          limit: itemsPerPage,
          filter: `categories.name_contains=Pizza`,
        });

        if (productErrors.value.length) {
          productErrors.value.forEach((error) =>
            showToast({ message: error.message, type: 'failure' })
          );
        }
      }
    };

    const addProductToOffer = (product: Product) => {
      if (selectedProducts.value.length >= props.offer.product_count) {
        showToast({
          message: `Vous pouvez seulement ajouter jusqu'à ${props.offer.product_count} produits`,
          type: 'failure',
        });
        return;
      }

      selectedProducts.value.push(product);
      showToast({ message: `${product.name} ajoutée` });
    };

    const removeProductFromOffer = (product: Product) => {
      const index = selectedProducts.value.findIndex(({ id }) => id === product.id);

      if (index !== -1) {
        selectedProducts.value.splice(index, 1);
        showToast({ message: `${product.name} supprimée` });
      }
    };

    const isSelected = (productId: number) => {
      return !!selectedProducts.value.find(({ id }) => id === productId);
    };

    const clearSelections = () => {
      selectedProducts.value = [];
    };

    const onDone = async () => {
      if (selectedProducts.value.length < props.offer.product_count) {
        showToast({
          message: `Veuillez finir de sélectionner les produits`,
          type: 'failure',
        });
        return;
      }

      if (isAction('create')) {
        await addCartOffer({
          offerItem: {
            offer: props.offer,
            products: selectedProducts.value,
          },
        });

        if (createErrors.value.length) {
          createErrors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
        }
      } else {
        await updateCartOffer({
          offerItemId: props.offerItemId,
          products: selectedProducts.value,
        });

        if (updateErrors.value.length) {
          updateErrors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
        }
      }

      if (!createErrors.value.length && !updateErrors.value.length) {
        const message = isAction('create')
          ? `${props.offer.name} ajoutée au panier.`
          : `La mise à jour a réussi`;
        showToast({
          message,
          type: 'link',
          link: '/checkout',
          linkText: 'Finaliser votre commande',
        });
        popup.value?.close();
      }
    };

    const isAction = (action: 'update' | 'create') => props.action === action;

    watch(
      () => popup.value?.show,
      async (popupOpen) => {
        if (popupOpen) {
          if (isAction('update')) {
            selectedProducts.value = [...props.defaultSelectedProducts];
          }
          paginateProducts(page.value);
        }
      }
    );
    watch(page, (newPage) => paginateProducts(newPage));

    return {
      popup,
      products,
      count,
      page,
      itemsPerPage,
      productsLoading,
      createLoading,
      updateLoading,
      selectedProducts,
      isAction,
      addProductToOffer,
      removeProductFromOffer,
      isSelected,
      onDone,
      clearSelections,
    };
  },
});
