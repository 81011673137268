<template>
  <footer class="px-6 pt-8 border-t border-gray-700 font-display md:px-8 md:pt-14">
    <div class="max-w-screen-2xl mx-auto">
      <div class="md:flex md:space-x-4">
        <div class="flex items-center md:w-2/5">
          <div class="w-24 h-24 md:w-36 md:h-28">
            <img src="@/assets/images/logo.png" alt="logo" class="w-full h-full object-fill" />
          </div>
          <p class="w-3/6 ml-6 text-sm font-body font-semibold text-gray-700 md:w-auto">
            {{ formattedBusinessHours }}
          </p>
        </div>

        <div class="w-full flex flex-col md:flex-row md:justify-around">
          <div class="mt-10 md:mt-0">
            <h5 class="text-2xl font-body text-gray-600 tracking-wide">Catégories</h5>
            <Skeleton :show="fetchingCategories">
              <ul v-if="categories.length" class="mt-4 space-y-4">
                <li
                  class="flex items-center space-x-2"
                  v-for="category in categories.slice(0, 3)"
                  :key="category.id"
                >
                  <router-link
                    :to="{ name: 'Shop', query: { page: 1, category: category.name } }"
                    class="
                      text-gray-800
                      border-b
                      transition-colors
                      hover:border-primary
                      focus:outline-none focus:border-primary
                    "
                    :class="[
                      $route.query.category === category.name
                        ? 'border-primary'
                        : 'border-transparent',
                    ]"
                  >
                    {{ category.name }}
                  </router-link>
                </li>
              </ul>

              <div v-else>
                <h6 class="text-gray-700 md:text-lg">Échec du chargement des catégories</h6>
              </div>

              <template #fallback>
                <FooterCategoriesSkeleton />
              </template>
            </Skeleton>
          </div>

          <div class="mt-10 md:mt-0">
            <h5 class="text-2xl font-body text-gray-600 tracking-wide">Contact</h5>
            <Skeleton :show="fetchingRestaurant">
              <ul v-if="restaurant" class="mt-4 space-y-4">
                <li class="flex items-center space-x-2">
                  <ChatIcon class="h-7 w-7 text-gray-500" />
                  <span class="text-gray-800">{{ restaurant.email }}</span>
                </li>
                <li class="flex items-center space-x-2">
                  <PhoneIcon class="h-7 w-7 text-gray-500" />
                  <span class="text-gray-800">{{ restaurant.phone }}</span>
                </li>
                <li class="flex items-center space-x-2">
                  <LocationMarkerIcon class="h-7 w-7 text-gray-500" />
                  <span class="text-gray-800">{{ restaurant.address }}</span>
                </li>
              </ul>

              <div v-else>
                <h6 class="text-gray-700 md:text-lg">
                  Échec du chargement des informations de contact
                </h6>
              </div>

              <template #fallback>
                <ContactInfoSkeleton />
              </template>
            </Skeleton>
          </div>
        </div>
      </div>

      <div
        class="
          mt-8
          px-4
          py-8
          text-center
          sm:flex sm:justify-between
          border-t border-gray-700
          lg:mt-16
        "
      >
        <span class="text-gray-900">&copy; 2021 Miam Pizza</span>
        <div
          class="
            mt-4
            text-gray-600
            flex flex-wrap
            items-center
            justify-center
            space-x-4
            sm:justify-start sm:mt-0
          "
        >
          <router-link
            :to="{ name: 'Privacy-Policy' }"
            class="transition-colors hover:text-gray-900"
          >
            Mention Legales
          </router-link>
          <router-link
            :to="{ name: 'Standard-Form-Contract' }"
            class="block transition-colors hover:text-gray-900 sm:inline-block"
          >
            Condition Général de Vente
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ChatIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import ContactInfoSkeleton from '@/components/skeleton/ContactInfoSkeleton.vue';
import FooterCategoriesSkeleton from '@/components/skeleton/FooterCategoriesSkeleton.vue';
import useRestaurant from '@/hooks/restaurant/use-restaurant';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';
import useCategories from '@/hooks/categories/use-categories';

export default defineComponent({
  components: {
    ChatIcon,
    PhoneIcon,
    LocationMarkerIcon,
    Skeleton,
    ContactInfoSkeleton,
    FooterCategoriesSkeleton,
  },
  setup() {
    const { restaurant, fetchingRestaurant } = useRestaurant();
    const { formattedBusinessHours } = useBusinessHours();
    const { categories, fetchingCategories, useGetCategories } = useCategories();
    const { getCategories } = useGetCategories();

    getCategories();

    return {
      restaurant,
      fetchingRestaurant,
      formattedBusinessHours,
      categories,
      fetchingCategories,
    };
  },
});
</script>
