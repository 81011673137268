
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'big',
    },
  },
});
