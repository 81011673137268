<template>
  <button
    class="
      text-xs
      py-3
      px-4
      flex
      justify-center
      items-center
      space-x-2
      tracking-wider
      rounded-lg
      transition-all
      focus:outline-none focus:ring-4
      sm:text-sm
    "
    :class="{ 'ring-4 ring-gray-300': loading }"
    :disabled="loading"
  >
    <span class="flex justify-center items-center">
      <slot />
    </span>
    <svg
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      aria-label="Loading..."
      v-show="loading"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
