<template>
  <router-link
    :to="href"
    class="px-2 py-3 space-x-2 text-xl flex rounded-md relative hover:bg-gray-200"
    :class="[href === $route.path ? 'bg-gray-200' : 'bg-transparent']"
  >
    <slot name="icon">
      <span v-if="icon" v-html="icon" class="text-gray-500"></span>
    </slot>
    <span :class="[href === $route.path ? 'text-gray-800' : 'text-gray-500']">
      {{ text }}
    </span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    href: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
  },
});
</script>
