<template>
  <div>
    <label
      :for="id"
      class="block text-sm text-gray-600"
      :class="[srOnlyLabel ? 'sr-only' : 'mb-1']"
    >
      {{ label }}
    </label>
    <select
      v-if="type === 'select'"
      :name="id"
      :id="id"
      @input="$emit('update:modelValue', $event?.target?.value)"
      :class="[{ [border]: border.length }, baseStyles]"
    >
      <option disabled :selected="!modelValue">{{ label }}</option>
      <slot />
    </select>

    <textarea
      v-else-if="type === 'textarea'"
      :id="id"
      :name="id"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event?.target?.value)"
      :class="[{ [border]: border.length }, baseStyles]"
      cols="30"
      rows="8"
    ></textarea>

    <input
      v-else
      :type="type"
      :id="id"
      :name="id"
      :value="modelValue"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event?.target?.value)"
      :class="[{ [border]: border.length }, baseStyles]"
    />
    <DisplayError :errors="errors" :errorkey="id" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ResponseError } from '@/lib/formatErrors';
import DisplayError from './DisplayError.vue';

export default defineComponent({
  components: { DisplayError },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    srOnlyLabel: {
      default: false,
    },
    placeholder: {
      default: '',
    },
    border: {
      default: 'rounded-lg',
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      default: 'text',
    },
    errors: {
      type: Array as PropType<ResponseError[]>,
      default: () => [],
    },
  },
  setup() {
    const baseStyles = `w-full py-2 px-3 border border-gray-light text-accent-1 focus:outline-none focus:ring-4 focus:ring-gray-light`;

    return {
      baseStyles,
    };
  },
});
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  color-adjust: exact;
  appearance: none;
}
</style>
