import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { saveToLocalStorage } from './utils';
import { getCartState } from './use-cart';
import useAuth from '@/hooks/auth/use-auth';
import { Product } from '@/types/models';
import { getSupplements } from './cart-add-offer';

const { isSignedIn } = useAuth();

const useUpdateCartOffer: UseUpdateCartOffer = () => {
  const state = getCartState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const localeUpdateItem = (payload: Payload) => {
    const index = state.items.findIndex((item) => item.offer_item?.id === payload.offerItemId);

    if (index !== -1) {
      const offerItem = state.items[index].offer_item;
      if (offerItem) {
        offerItem.products = payload.products;
        state.items[index].offer_item = offerItem;
      }
    }
  };

  const remoteUpdateItem = async (payload: Payload) => {
    const item = state.items.find((item) => item.offer_item?.id === payload.offerItemId);
    if (!item) return;

    await request({
      url: `/cart-items/${item.id}`,
      method: 'PUT',
      data: {
        cartType: 'offer',
        offerItemId: payload.offerItemId,
        productIds: payload.products.map(({ id }) => id),
        supplements: getSupplements(payload.products),
      },
    });
  };

  const updateCartOffer = async (payload: Payload) => {
    if (isSignedIn.value) {
      await remoteUpdateItem(payload);
      if (!errors.value.length) localeUpdateItem(payload);
    } else {
      localeUpdateItem(payload);
    }

    saveToLocalStorage(state.items);
  };

  return { loading, errors, updateCartOffer };
};

export default useUpdateCartOffer;

export type UseUpdateCartOffer = () => {
  updateCartOffer(payload: Payload): void;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type Payload = {
  offerItemId: number;
  products: Product[];
};
