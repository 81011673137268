import { reactive, toRefs, ToRefs } from 'vue';

import { User } from '@/types/models';
import { shouldUpdateUserData } from './utils';
import { fetchUser } from './user-fetch';
import { UseUpdateUser, useUpdateUser } from './user-update';

const state: State = reactive({
  user: null,
  loadingUser: false,
});

export const getUserState = (): State => state;

const useUser: UseUser = () => ({
  ...toRefs(state),
  fetchUser,
  useUpdateUser,
  shouldUpdateUserData,
});

export default useUser;

type State = {
  user: User | null;
  loadingUser: boolean;
};

type UseUser = () => ToRefs<State> & {
  fetchUser: typeof fetchUser;
  shouldUpdateUserData(): boolean;
  useUpdateUser: UseUpdateUser;
};
