
import { defineComponent } from 'vue';

import useCart from '@/hooks/cart/use-cart';

export default defineComponent({
  setup() {
    const { openCart, count } = useCart();

    return {
      openCart,
      count,
    };
  },
});
