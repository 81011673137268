
import { defineComponent } from 'vue';
import { useMeta } from 'vue-meta';

import { homeMetaTags } from '@/lib/metaTags';
import HeroSlider from '@/components/home/HeroSlider.vue';
import ProductsPopular from '@/components/home/ProductsPopular.vue';
import NewsLetter from '@/components/home/NewsLetter.vue';

export default defineComponent({
  components: { HeroSlider, ProductsPopular, NewsLetter },
  name: 'Home',
  setup() {
    useMeta(homeMetaTags());
  },
});
