import { CartItem } from '@/types/models';
import formatMoney from './formatMoney';
import { formatOfferItem } from './formatOffer';
import formatProduct from './formatProduct';

export default function formatCartItem(cartItem: CartItem): CartItem {
  return {
    ...cartItem,
    product: cartItem.product ? formatProduct(cartItem.product) : undefined,
    offer_item: cartItem.offer_item ? formatOfferItem(cartItem.offer_item) : undefined,
    size: {
      ...cartItem.size,
      price: Number(cartItem.size.price),
      formattedPrice: formatMoney(Number(cartItem.size.price)),
    },
  };
}
