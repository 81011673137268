
import { defineComponent } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import AppButton from '@/components/shared/AppButton.vue';
import useAuth from '@/hooks/auth/use-auth';
import useCart from '@/hooks/cart/use-cart';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { AppInput, DisplayError, AppButton },
  props: {
    getRecaptchaToken: {
      type: Function,
      required: true,
    },
    captchaLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { changeAuthAction, closeAuthModal, useSignIn, afterAuthAction } = useAuth();
    const { signIn, loading, errors } = useSignIn();
    const { useGetCartItems, itemsSynced } = useCart();
    const { getCartItems } = useGetCartItems();
    const { showToast } = useToast();

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const identifier = (elements.namedItem('email') as HTMLInputElement).value;
      const password = (elements.namedItem('password') as HTMLInputElement).value;

      const captchaToken = await props.getRecaptchaToken();
      if (!captchaToken) return;

      await signIn({ identifier, password, captchaToken });

      if (!errors.value.length) {
        closeAuthModal();
        (e.target as HTMLFormElement).reset();

        await getCartItems();

        if (itemsSynced.value) {
          const message =
            'Vous avez laissé des articles dans votre panier la dernière fois que vous étiez ici, nous les avons eu pour vous !';
          showToast({ message }, -1);
        }

        if (afterAuthAction.value.action) {
          afterAuthAction.value.action();
        }
      }
    };

    return {
      changeAuthAction,
      errors,
      loading,
      onSubmit,
    };
  },
});
