import { computed, reactive, ToRefs, toRefs } from 'vue';

import { BusinessHours } from '@/types/models';
import { useGetBusinessHours, UseGetBusinessHours } from './business-hours-get';
import { getWorkingHours, isWithinBusinessHours } from './utils';
import useRestaurant from '../restaurant/use-restaurant';

const state: State = reactive({
  businessHours: [],
  fetchingBusinessHours: false,
  currentlyOpen: computed(() => {
    const { restaurant } = useRestaurant();

    if (!restaurant.value?.is_open) return false;

    const currentDay = state.businessHours.find((item) => item.currentDay);

    if (!currentDay) return false;

    return isWithinBusinessHours(currentDay);
  }),
  formattedBusinessHours: computed(() => {
    const currentDay = state.businessHours.find((item) => item.currentDay);

    if (!currentDay) return 'Désolé, nous sommes fermés';

    return getWorkingHours(currentDay);
  }),
  currentDay: computed(() => state.businessHours.find((item) => item.currentDay)?.day || ''),
});

export const getBusinessHoursState = (): State => state;

const useBusinessHours: UseBusinessHours = () => ({
  ...toRefs(state),
  useGetBusinessHours,
});

export default useBusinessHours;

type State = {
  businessHours: BusinessHours[];
  fetchingBusinessHours: boolean;
  currentlyOpen: boolean;
  formattedBusinessHours: string;
  currentDay: string;
};

type UseBusinessHours = () => ToRefs<State> & {
  useGetBusinessHours: UseGetBusinessHours;
};
