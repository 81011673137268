
import { defineComponent, ref } from 'vue';
import PopupModal from './PopupModal.vue';

export default defineComponent({
  components: { PopupModal },
  emits: ['on-confirm'],
  setup(_, { emit }) {
    const popup = ref<InstanceType<typeof PopupModal>>();

    const onConfirm = () => {
      emit('on-confirm');
      popup.value?.close();
    };

    return {
      popup,
      onConfirm,
    };
  },
});
