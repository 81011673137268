import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';

export const useForgotPassword: UseForgotPassword = () => {
  const { errors, loading, request } = useRequest();

  const requestResetLink = async (payload: forgotPasswordPayload) => {
    await request({ url: `/auth/forgot-password`, method: 'POST', data: payload });
  };

  return {
    requestResetLink,
    loading,
    errors,
  };
};

export type UseForgotPassword = () => {
  requestResetLink(payload: forgotPasswordPayload): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type forgotPasswordPayload = { email: string; captchaToken: string };
