import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { CartItem } from '@/types/models';
import { getCartState } from './use-cart';
import useAuth from '@/hooks/auth/use-auth';
import { saveToLocalStorage } from './utils';

const { isSignedIn } = useAuth();

const useRemoveCartItem: UseRemoveCartItem = () => {
  const state = getCartState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const localRemoveItem = (item: CartItem) => {
    const index = state.items.findIndex((cartItem) => cartItem.id === item.id);

    if (index !== -1) {
      state.items.splice(index, 1);
    }
  };

  const remoteRemoveItem = async (item: CartItem) => {
    await request({ url: `/cart-items/${item.id}`, method: 'DELETE' });
  };

  const removeCartItem = async (item: CartItem) => {
    if (isSignedIn.value) {
      await remoteRemoveItem(item);
      localRemoveItem(item);
    } else {
      localRemoveItem(item);
    }

    saveToLocalStorage(state.items);
  };

  return { loading, errors, removeCartItem };
};

export default useRemoveCartItem;

export type UseRemoveCartItem = () => {
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
  removeCartItem(item: CartItem): Promise<void>;
};
