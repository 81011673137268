import { Ref, ref } from 'vue';
import axios, { AxiosRequestConfig } from 'axios';

import { getBackendURL } from './env';
import formatErrors, { ResponseError } from './formatErrors';

const backendURL = getBackendURL();

const http = axios.create({
  baseURL: backendURL,
});

export const authHttp = axios.create({
  baseURL: backendURL,
});

export const setAuthToken = (token?: string): void => {
  if (token) {
    authHttp.defaults.headers.common['authorization'] = `Bearer ${token}`;
  } else {
    delete authHttp.defaults.headers.common['authorization'];
  }
};

export const useRequest: UseRequest = (options = {} as UseRequestOptions) => {
  const loading = ref(false);
  const errors = ref<ResponseError[]>([]);
  const client = options.authClient ? authHttp : http;

  const request = async <T>(config: Config<T>): Promise<T | undefined> => {
    try {
      loading.value = true;

      const { data } = await client(config);

      errors.value = [];

      if (config.onSuccess) config.onSuccess(data);

      return data as T;
    } catch (error) {
      errors.value = formatErrors(error as Error);

      if (config.onError) config.onError(error as Error);
    } finally {
      loading.value = false;
    }
  };

  return { loading, errors, request };
};

type Config<T> = AxiosRequestConfig & {
  onSuccess?(data: T): void;
  onError?(error: Error): void;
};

type UseRequestOptions = { authClient: boolean };

type UseRequest = (options?: UseRequestOptions) => {
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
  request<T>(config: Config<T>): Promise<T | undefined>;
};

export default http;
