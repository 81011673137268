import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { findDuplicateIndex, saveToLocalStorage } from './utils';
import { getCartState } from './use-cart';
import useAuth from '@/hooks/auth/use-auth';
import { ProductSize, Supplement } from '@/types/models';

const { isSignedIn } = useAuth();

const useUpdateCartProduct: UseUpdateCartProduct = () => {
  const state = getCartState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const localeUpdateItem = (payload: Payload) => {
    const index = state.items.findIndex((item) => item.id === payload.id);

    if (index !== -1) {
      const duplicateIndex = findDuplicateIndex(
        state.items[index],
        payload.size,
        payload.supplements
      );

      if (duplicateIndex !== -1) {
        state.items[index].size = payload.size;
        state.items[index].supplements = payload.supplements;
        state.items[index].quantity += state.items[duplicateIndex].quantity;
        state.items.splice(duplicateIndex, 1);
      } else {
        state.items[index].size = payload.size;
        state.items[index].supplements = payload.supplements;

        if (state.items[index].quantity >= 1) {
          state.items[index].quantity = payload.quantity;
        }
      }
    }
  };

  const remoteUpdateItem = async (payload: Payload) => {
    const item = state.items.find((item) => item.id === payload.id);
    if (!item) return;

    const cartData = {
      cartType: 'product',
      size: payload.size,
      quantity: payload.quantity > item.quantity ? 1 : -1,
      supplements: payload.supplements,
    };

    await request({ url: `/cart-items/${item.id}`, method: 'PUT', data: cartData });
  };

  const updateCartProduct = async (payload: Payload) => {
    if (isSignedIn.value) {
      await remoteUpdateItem(payload);
      if (!errors.value.length) localeUpdateItem(payload);
    } else {
      localeUpdateItem(payload);
    }

    saveToLocalStorage(state.items);
  };

  return { loading, errors, updateCartProduct };
};

export default useUpdateCartProduct;

export type UseUpdateCartProduct = () => {
  updateCartProduct(payload: Payload): void;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type Payload = {
  id: number;
  quantity: number;
  size: ProductSize;
  supplements: Supplement[];
};
