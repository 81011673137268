<template>
  <div class="flex items-center space-x-2">
    <input
      type="checkbox"
      :id="id"
      :name="id"
      :checked="checked"
      @input="$emit('update:checked', $event.target.checked)"
    />
    <label :for="id" class="text-sm text-gray-700 sm:text-base">{{ label }}</label>
  </div>
  <DisplayError :errors="errors" :errorkey="id" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import DisplayError from './DisplayError.vue';

export default defineComponent({
  components: { DisplayError },
  emits: ['update:checked'],
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    errors: {
      type: Array as PropType<ResponseError[]>,
      default: () => [],
    },
  },
});
</script>
