<template>
  <div class="grid gap-8 grid-cols-1 sm:grid-cols-2 md:gap-12 lg:grid-cols-3 2xl:grid-cols-4">
    <OfferCard v-show="offers.length" v-for="offer in offers" :key="offer.id" :offer="offer" />
    <p v-show="!offers.length" class="p-2">No formulas ...</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Offer } from '@/types/models';
import OfferCard from './OfferCard.vue';

export default defineComponent({
  components: { OfferCard },
  props: {
    offers: {
      type: Array as PropType<Offer[]>,
      required: true,
    },
  },
});
</script>
