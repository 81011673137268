<template>
  <div class="flex flex-col justify-center">
    <h3 class="text-4xl font-heading text-center">S'inscrire</h3>
    <DisplayError :errors="errors" errorkey="Auth" class="text-center mt-3" />

    <form class="mt-6 space-y-4" @submit.prevent="onSubmit">
      <AppInput id="first_name" label="Prénom" :errors="errors" v-model="registerData.first_name" />
      <AppInput id="last_name" label="Nom" :errors="errors" v-model="registerData.last_name" />
      <AppInput id="email" label="E-mail" :errors="errors" v-model="registerData.email" />
      <AppInput
        id="password"
        type="password"
        label="Mot de passe"
        :errors="errors"
        v-model="registerData.password"
      />
      <AppCheckbox
        id="newsletter_subscribe"
        :checked="registerData.newsletter_subscribe"
        v-model:checked="registerData.newsletter_subscribe"
        label="S'abonner à la newsletter"
        :errors="errors"
      />
      <AppCheckbox
        id="agreed_to_terms"
        v-model:checked="registerData.agreed_to_terms"
        label="J'accepte les termes et conditions"
        :errors="errors"
      />
      <AppButton
        class="w-full bg-primary text-white hover:ring-red-200 focus:ring-red-200"
        :loading="loading || captchaLoading"
        type="submit"
      >
        S'inscrire
      </AppButton>
    </form>
    <div class="mt-6 space-x-1 flex items-center justify-center">
      <p class="text-gray-600">Vous avez déjà un compte?</p>
      <button
        class="
          text-primary
          font-medium
          cursor-pointer
          transition-transform
          hover:scale-105
          focus:outline-none focus:scale-105
        "
        @click="changeAuthAction('signIn')"
      >
        S'identifier
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import useToast from '@/hooks/use-toast';
import useAuth from '@/hooks/auth/use-auth';
import useCart from '@/hooks/cart/use-cart';

export default defineComponent({
  components: { AppInput, AppButton, DisplayError, AppCheckbox },
  props: {
    getRecaptchaToken: {
      type: Function,
      required: true,
    },
    captchaLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { showToast } = useToast();
    const { changeAuthAction, closeAuthModal, useSignUp, afterAuthAction } = useAuth();
    const { signUp, loading, errors } = useSignUp();
    const { itemsSynced, useGetCartItems } = useCart();
    const { getCartItems } = useGetCartItems();
    const registerData = ref({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      newsletter_subscribe: true,
      agreed_to_terms: false,
      captchaToken: '',
    });

    const onSubmit = async (e: Event) => {
      const captchaToken = await props.getRecaptchaToken();

      if (!captchaToken) return;

      registerData.value.captchaToken = captchaToken;

      await signUp(registerData.value);

      if (!errors.value.length) {
        closeAuthModal();
        (e.target as HTMLFormElement).reset();

        await getCartItems();

        if (itemsSynced.value) {
          const message =
            'Vous avez laissé des articles dans votre panier la dernière fois que vous étiez ici, nous les avons eu pour vous !';
          showToast({ message }, -1);
        }

        if (afterAuthAction.value.action) {
          afterAuthAction.value.action();
        }
      }
    };

    return {
      registerData,
      errors,
      loading,
      changeAuthAction,
      onSubmit,
    };
  },
});
</script>
