import { Ref } from 'vue';

import { Offer } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { getOffersState } from './use-offers';
import { formatOffer } from '@/lib/formatOffer';

export const useAllOffers: UseAllOffers = () => {
  const state = getOffersState();
  const { errors, loading, request } = useRequest();

  const getAllOffers = async ({ start, limit }: Options = {}) => {
    if (state.count === undefined) {
      getOffersCount();
    }

    if (start === undefined) {
      start = 0;
    }

    if (limit === undefined) {
      limit = 9;
    }

    const params = `_start=${start}&_limit=${limit}`;
    const data = await request<Offer[]>({ url: `/offers?${params}` });

    if (data) {
      state.offers = data.map(formatOffer);
    }
  };

  return { loading, errors, getAllOffers };
};

const getOffersCount = async () => {
  const state = getOffersState();
  const { request } = useRequest();
  state.count = await request<number>({ url: `/offers/count` });
};

export type UseAllOffers = () => {
  getAllOffers(opts?: Options): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type Options = {
  start?: number;
  limit?: number;
};
