
import { defineComponent } from 'vue';

import useBusinessHours from '@/hooks/business-hours/use-business-hours';

export default defineComponent({
  setup() {
    const { currentlyOpen, useGetBusinessHours } = useBusinessHours();
    const { getBusinessHours } = useGetBusinessHours();

    getBusinessHours();

    return {
      currentlyOpen,
    };
  },
});
