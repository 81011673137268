import { Ref } from 'vue';

import { Product } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import formatProduct from '@/lib/formatProduct';
import { getProductsState } from './use-products';

export const useAllProducts: UseAllProducts = () => {
  const state = getProductsState();
  const { errors, loading, request } = useRequest();

  const getAllProducts = async ({ start, limit, filter }: Options = {}) => {
    if (state.count === undefined) {
      getProductCount();
    }

    if (start === undefined) {
      start = 0;
    }

    if (limit === undefined) {
      limit = 9;
    }

    let params = `_start=${start}&_limit=${limit}`;

    if (filter !== undefined) {
      params += `&${filter}`;
    }

    const data = await request<Product[]>({ url: `/products?${params}` });

    if (data) {
      state.products = data.map(formatProduct);

      if (filter !== undefined) {
        await getProductCount(filter);
      } else {
        state.filteredProductsCount = undefined;
      }
    }
  };

  return { loading, errors, getAllProducts };
};

const getProductCount = async (filters?: string) => {
  const state = getProductsState();
  const { request } = useRequest();
  let url = `/products/count`;

  if (filters) {
    url += `?${filters}`;
    state.filteredProductsCount = await request<number>({ url });
  } else {
    state.count = await request<number>({ url });
  }
};

export type UseAllProducts = () => {
  getAllProducts(options?: Options): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type Options = {
  start?: number;
  limit?: number;
  filter?: string;
};
