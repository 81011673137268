<template>
  <transition name="fade">
    <div
      v-if="show"
      :class="[variant === 'coverScreen' ? 'fixed' : 'absolute', { 'inset-0': center }, 'z-30']"
    >
      <div
        :class="[
          showBackdrop ? (fixBackDrop ? 'fixed' : 'absolute') : '',
          { 'z-30 inset-0 p-4 flex items-center bg-gray-900/70': showBackdrop },
        ]"
        @click="(e) => shouldCloseBackDrop && closeBackdrop(e)"
      >
        <div class="mx-auto relative" id="popupContainer">
          <button
            class="p-2 bg-white rounded-full shadow-md absolute z-30 -top-3 -right-3 group"
            @click="close"
          >
            <XIcon class="w-4 h-4 group-hover:text-primary transition-colors" />
          </button>
          <transition name="scale" appear>
            <slot></slot>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { XIcon } from '@heroicons/vue/outline';

export type ModalVaraint = 'coverScreen' | 'relative';

export default defineComponent({
  emits: ['on-close'],
  components: { XIcon },
  props: {
    variant: {
      type: String as PropType<ModalVaraint>,
      default: 'coverScreen',
    },
    showBackdrop: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: true,
    },
    fixBackDrop: {
      type: Boolean,
      default: true,
    },
    shouldCloseBackDrop: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(false);

    const open = () => {
      show.value = true;
    };

    const close = () => {
      show.value = false;
      emit('on-close');
    };

    const closeBackdrop = ({ target }: Event) => {
      if (!props.showBackdrop) return;

      const container = document.getElementById('popupContainer') as HTMLElement | null;

      if (container === target || !container?.contains(target as HTMLElement)) {
        close();
      }
    };

    return {
      show,
      open,
      close,
      closeBackdrop,
    };
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.scale-enter-from {
  transition-delay: 0.35s;
}
.scale-enter-active,
.scale-leave-active {
  transition: all 0.25s;
}
.scale-enter-from,
.scale-leave-to {
  transform: scale(1.5);
}
</style>
