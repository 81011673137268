<template>
  <transition name="fade" mode="out-in">
    <div v-if="!show">
      <slot />
    </div>
    <div v-else>
      <slot name="fallback">
        <div class="hidden w-full p-4 justify-end items-center space-x-3 animate-pulse md:flex">
          <div class="rounded-full bg-gray-300 h-6 w-6"></div>
          <div class="rounded-full bg-gray-300 h-6 w-6"></div>
          <div class="rounded-full bg-gray-300 h-6 w-6"></div>

          <div class="hidden h-6 bg-gray-300 rounded w-2/5 lg:block"></div>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
