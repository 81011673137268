<template>
  <Skeleton :show="loading">
    <div
      id="category-container"
      class="py-4 px-2 flex space-x-4 overflow-x-auto whitespace-nowrap md:space-x-8"
    >
      <AppButton
        v-for="category in categories"
        :key="category.id"
        :class="[
          btnStyles,
          activeCategory?.slug === category.slug ? 'bg-secondary text-white' : 'bg-white',
        ]"
        @click="$emit('update:activeCategory', category)"
      >
        {{ category.name }}
      </AppButton>
    </div>

    <template #fallback>
      <CategoriesSkeleton />
    </template>
  </Skeleton>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Category } from '@/types/models';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import CategoriesSkeleton from '@/components/skeleton/CategoriesSkeleton.vue';
import AppButton from '@/components/shared/AppButton.vue';
import useCategories from '@/hooks/categories/use-categories';

export default defineComponent({
  components: { AppButton, Skeleton, CategoriesSkeleton },
  emits: ['update:activeCategory'],
  props: {
    activeCategory: {
      type: Object as PropType<Category>,
      required: true,
    },
  },
  setup() {
    const { categories, useGetCategories } = useCategories();
    const { getCategories, loading } = useGetCategories();
    const btnStyles =
      'px-6 font-display capitalize font-normal rounded-xl shadow-md transition-colors hover:bg-secondary hover:text-white  md:text-lg';

    getCategories();

    return {
      btnStyles,
      categories,
      loading,
    };
  },
});
</script>

<style scoped>
#category-container::-webkit-scrollbar {
  display: none;
}
#category-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
