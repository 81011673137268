<template>
  <transition name="slide">
    <div
      v-show="cartOpen"
      class="w-11/12 fixed inset-y-0 z-40 right-0 shadow-md sm:w-[460px] md:w-[500px]"
      id="cart"
    >
      <div class="h-full p-4 pb-2 space-y-4 flex flex-col justify-between bg-accent sm:pt-12">
        <div class="min-h-[400px] h-5/6 overflow-hidden space-y-4 sm:space-y-8">
          <header class="flex justify-between items-center">
            <h4 class="text-2xl font-heading sm:text-3xl md:text-3xl">Votre panier</h4>
            <button class="w-7 h-7 group focus:outline-none" @click="closeCart">
              <span class="sr-only">Fermer le panier</span>
              <ArrowCircleRightIcon
                class="
                  w-full
                  h-full
                  text-gray-500
                  group-hover:text-gray-700
                  group-focus:text-gray-700
                "
              />
            </button>
          </header>
          <transition name="fade" mode="out-in">
            <transition-group
              name="list"
              tag="div"
              appear
              v-if="items.length"
              class="h-full pr-1 pb-14 space-y-4 overflow-y-auto sm:mt-8 sm:pb-20"
              id="cart-items"
            >
              <CartItem v-for="item in items" :key="item.id" :item="item" />
            </transition-group>
            <div v-else class="h-full p-4 flex justify-center items-center sm:p-8">
              <p class="text-sm sm:text-base">
                Votre panier est vide!
                <router-link
                  :to="{ name: 'Shop' }"
                  class="text-primary border-b-2 border-red-400 hover:text-red-700"
                  @click="closeCart"
                >
                  Ajouter des Produits </router-link
                >, puis Finaliser votre commande.
              </p>
            </div>
          </transition>
        </div>

        <div class="space-y-4 flex flex-col justify-between">
          <div class="space-y-2">
            <div class="flex justify-between font-display">
              <span class="text-lg font-semibold">Article(s):</span>
              <span class="text-lg">{{ count }}</span>
            </div>
            <div class="flex justify-between font-display">
              <span class="text-lg font-semibold">Total:</span>
              <span class="text-lg">{{ formattedSubTotal }}</span>
            </div>
          </div>

          <router-link
            :to="count === 0 || !isSignedIn ? '#' : '/checkout'"
            class="py-3 text-center tracking-wider rounded-2xl"
            :class="[
              count === 0
                ? 'bg-gray-300 text-gray-400 cursor-not-allowed'
                : 'bg-primary text-white font-semibold',
            ]"
            :aria-disabled="count === 0"
            @click="navigateToCheckout"
          >
            Finaliser votre commande
          </router-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { ArrowCircleRightIcon } from '@heroicons/vue/outline';

import CartItem from './CartItem.vue';
import closeOnOutsideClick from '@/lib/closeOnOutsideClick';
import useCart from '@/hooks/cart/use-cart';
import useAuth from '@/hooks/auth/use-auth';

export default defineComponent({
  components: { CartItem, ArrowCircleRightIcon },
  setup() {
    const router = useRouter();
    const { isSignedIn, openAuthModal } = useAuth();
    const state = useCart();

    closeOnOutsideClick(state.cartOpen, '#cart');

    const navigateToCheckout = () => {
      if (isSignedIn.value) {
        router.push({ name: 'Checkout' });
      } else {
        if (state.count.value > 0) {
          openAuthModal('signIn', { action: navigateToCheckout });
        }
      }

      state.closeCart();
    };

    return {
      ...state,
      navigateToCheckout,
      isSignedIn,
    };
  },
});
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-leave-active {
  position: absolute;
}

#cart-items {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) rgb(208, 208, 208);
}

#cart-items::-webkit-scrollbar {
  width: 8px;
}

#cart-items::-webkit-scrollbar-track {
  background: rgb(208, 208, 208);
}

#cart-items::-webkit-scrollbar-thumb {
  background: #eecfc3;
}

#cart-items::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  border-radius: 10px;
}

#cart-items::-webkit-scrollbar-thumb:active {
  background: var(--primary);
  border-radius: 10px;
}
</style>
