<template>
  <div
    v-if="variant === 'big'"
    class="h-[680px] flex flex-col animate-pulse md:flex-row md:rounded-tr-none md:h-[600px]"
  >
    <div class="h-full w-full flex items-center justify-center bg-gray-200">
      <div class="w-5/6 h-5/6 rounded bg-gray-100"></div>
    </div>
    <div class="h-full w-full p-4 flex flex-col justify-center bg-gray-300">
      <div class="flex justify-between items-center space-x-4">
        <div class="h-6 rounded w-2/5 bg-gray-200"></div>
        <div class="h-6 w-6 rounded-full bg-gray-200"></div>
      </div>
      <div class="mt-8">
        <div class="h-3 w-4/5 rounded bg-gray-200"></div>
        <div class="h-3 w-3/5 mt-2 rounded bg-gray-200"></div>
      </div>

      <div class="mt-8 flex space-x-4">
        <div class="h-8 w-2/5 rounded bg-gray-200"></div>
      </div>
    </div>
  </div>

  <div v-else class="h-80 w-full rounded-2xl shadow-md overflow-hidden animate-pulse sm:h-96">
    <div class="h-3/5 bg-gray-300" />
    <div class="p-4 h-3/5 bg-gray-200">
      <div class="h-6 rounded w-4/5 bg-gray-300" />
      <div class="mt-8 flex justify-between items-center space-x-4">
        <div class="h-7 rounded w-2/5 bg-gray-300" />
        <div class="flex justify-between items-center space-x-4">
          <div class="h-7 w-7 rounded-full bg-gray-300" />
          <div class="h-7 w-7 rounded-full bg-gray-300" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'big',
    },
  },
});
</script>
