import { Ref } from 'vue';

import { User } from '@/types/models';
import { useRequest } from '@/lib/http';
import { getUserState } from './use-user';
import { ResponseError } from '@/lib/formatErrors';

export const useUpdateUser: UseUpdateUser = () => {
  const state = getUserState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const updateUser = async <T>(payload: T) => {
    const data = await request<User>({ url: `users/me`, method: 'PUT', data: payload });

    if (data) {
      state.user = data;
    }
  };

  return { errors, loading, updateUser };
};

export type UseUpdateUser = () => {
  errors: Ref<ResponseError[]>;
  loading: Ref<boolean>;
  updateUser: <T>(payload: T) => Promise<void>;
};
