
import { defineComponent, PropType, ref } from 'vue';
import { PlusIcon, XIcon } from '@heroicons/vue/outline';
import { HeartIcon } from '@heroicons/vue/solid';

import { Product, Supplement } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import ProductSupplementsModal from './ProductSupplementsModal.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useWishlist from '@/hooks/wishlist/use-wishlist';
import useAuth from '@/hooks/auth/use-auth';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    AppRoundButton,
    PlusIcon,
    HeartIcon,
    XIcon,
    ProductSupplementsModal,
  },
  emits: ['product:add', 'product:remove'],
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isSignedIn, openAuthModal } = useAuth();
    const { useAddWishlistItem, inWishlist } = useWishlist();
    const { showToast } = useToast();
    const { addWishlistItem, loading, errors: wishlistErrors } = useAddWishlistItem();
    const selectedSupplements = ref<Supplement[]>([]);
    const supplementsModal = ref<InstanceType<typeof ProductSupplementsModal>>();

    const addToWishlist = async () => {
      if (!isSignedIn.value) {
        return openAuthModal('signIn', { action: addToWishlist });
      }

      await addWishlistItem(props.product);

      if (!wishlistErrors.value.length) {
        showToast({ message: `${props.product.name} ajouté à la liste de souhaits` });
      } else {
        wishlistErrors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    const saveSupplements = (supplements: Supplement[]) => {
      selectedSupplements.value = supplements;

      emit('product:add', { ...props.product, supplements: selectedSupplements.value });
    };

    const removeOfferItem = () => {
      if (props.isSelected) {
        emit('product:remove', props.product);
      }
    };

    const addOfferItem = () => {
      if (props.product.supplements.length) {
        supplementsModal.value?.popup?.open();
      } else {
        emit('product:add', props.product);
      }
    };

    return {
      loading,
      supplementsModal,
      saveSupplements,
      addOfferItem,
      removeOfferItem,
      addToWishlist,
      inWishlist,
      getLowestPrice,
    };
  },
});
