
import { defineComponent, ref } from 'vue';

import AppInput from '@/components/shared/AppInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import { useRequest } from '@/lib/http';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppInput, AppButton },
  setup() {
    const { showToast } = useToast();
    const { user } = useUser();
    const { loading, errors, request } = useRequest();
    const newsLetterData = ref({
      first_name: user.value ? user.value.first_name : '',
      last_name: user.value ? user.value.last_name : '',
      email: '',
    });

    const onSubmit = async (e: Event) => {
      await request({
        url: `/newsletter-subscriptions`,
        method: 'POST',
        data: newsLetterData.value,
      });

      if (errors.value.length) {
        errors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      } else {
        showToast({ message: 'Vous vous êtes inscrit avec succès à notre newsletter' });
        (e.target as HTMLFormElement).reset();
      }
    };

    return {
      newsLetterData,
      loading,
      errors,
      onSubmit,
    };
  },
});
