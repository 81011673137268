import { Ref } from 'vue';

import { CartItem, OfferItem, Product, Supplement } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { saveCartItem } from './utils';
import { getCartState } from './use-cart';
import useAuth from '@/hooks/auth/use-auth';

const { isSignedIn } = useAuth();

const useAddCartOffer: UseAddCartOffer = () => {
  const state = getCartState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const localAddCartOffer = (payload: CartOfferPayload) => {
    const cartItem: CartItem = {
      id: state.items[0]?.id + 1 || 1,
      offer_item: { ...payload.offerItem, id: 1 }, // add dummy id
      quantity: 1,
      size: {
        name: payload.offerItem.offer.allowed_sizes,
        price: payload.offerItem.offer.price,
        formattedPrice: payload.offerItem.offer.formattedPrice,
      },
      supplements: getSupplements(payload.offerItem.products),
    };

    saveCartItem(cartItem, { formatItem: false, setSync: true });
  };

  const remoteAddCartOffer = async (payload: CartOfferPayload) => {
    const data = await request<CartItem>({
      url: `/cart-items`,
      method: 'POST',
      data: {
        cartType: 'offer',
        offerId: payload.offerItem.offer.id,
        productIds: payload.offerItem.products.map(({ id }) => id),
        supplements: getSupplements(payload.offerItem.products),
      },
    });

    if (data) {
      saveCartItem(data);
    }
  };

  const addCartOffer = async (payload: CartOfferPayload) => {
    if (isSignedIn.value) {
      await remoteAddCartOffer(payload);
    } else {
      localAddCartOffer(payload);
    }
  };

  return { loading, errors, addCartOffer };
};

export default useAddCartOffer;

export const getSupplements = (products: Product[]): Supplement[] => {
  const supplements: Supplement[] = [];

  products.forEach((product) => {
    product.supplements.forEach((supplement) => {
      supplements.push({ ...supplement, productId: product.id });
    });
  });

  return supplements;
};

export type UseAddCartOffer = () => {
  addCartOffer(item: CartOfferPayload): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type CartOfferPayload = {
  offerItem: Omit<OfferItem, 'id'>;
};
