import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { getWishlistState } from './use-wishlist';

export const useRemoveWishlistItem: UseRemoveWishlistItem = () => {
  const state = getWishlistState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const removeWishlistItem = async (productId: number) => {
    const wishlistId = state.wishlist?.id;

    await request({ url: `/wishlists/${wishlistId}/${productId}`, method: 'DELETE' });

    if (!errors.value.length && state.wishlist) {
      const index = state.wishlist.products.findIndex(({ id }) => id === productId);
      if (index !== -1) {
        state.wishlist.products.splice(index, 1);
      }
    }
  };

  return { loading, errors, removeWishlistItem };
};

export type UseRemoveWishlistItem = () => {
  removeWishlistItem(productId: number): void;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
