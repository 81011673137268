<template>
  <PopupModal ref="popup" variant="coverScreen" :fixBackDrop="false" @on-close="onCancel">
    <div class="p-4 sm:p-8 rounded-md bg-white max-h-screen overflow-y-auto overflow-hidden">
      <ProductSupplements
        v-if="item.product?.supplements.length"
        :supplements="item.product.supplements"
        :defaultSupplements="item.supplements"
        v-model:supplements="selectedSupplements"
        :loading="loading"
      />

      <div
        class="pb-4 sm:pb-6 sm:min-w-[460px]"
        :class="{ 'py-4 sm:py-6 border-t': item.product?.supplements?.length }"
      >
        <h4 class="mb-6 text-xl font-display text-gray-700 sm:text-2xl">
          {{ item.product?.name }}:
        </h4>

        <div
          class="space-y-2 sm:space-y-0 sm:flex sm:items-center"
          :class="[!item.product?.variations ? 'sm:space-x-28' : 'sm:space-x-4 sm:justify-between']"
        >
          <div class="space-y-4">
            <span class="font-heading text-gray-600">Prix:</span>
            <p class="relative">
              <span
                v-if="item.product?.variations && !size?.formattedPrice"
                class="w-20 absolute -top-3 text-xs text-gray-600"
              >
                A partir de
              </span>
              <span class="text-2xl md:text-3xl font-semibold">
                {{ size?.formattedPrice || getLowestPrice(item.product) }}
              </span>
            </p>
          </div>
          <div v-if="item.product?.variations" class="space-y-4">
            <span class="font-heading text-gray-600">Taille:</span>
            <ProductSizeInput
              :size="size"
              v-model:size="size"
              :sizes="item.product.variations.sizes"
            />
          </div>
          <div class="space-y-4">
            <span class="font-heading text-gray-600">Quantité:</span>
            <ProductQuantityInput v-model:quantity="quantity" :loading="loading" />
          </div>
        </div>
      </div>

      <div class="pt-4 border-t text-center space-x-2 flex justify-center items-center">
        <AppButton
          class="
            w-full
            border-2 border-transparent
            text-gray-800
            hover:border-gray-800
            focus:outline-none focus:border-gray-800
          "
          @click="onCancel"
        >
          Annuler
        </AppButton>
        <AppButton
          class="
            w-full
            border-2
            uppercase
            text-white
            bg-primary
            hover:bg-red-600
            focus:outline-none focus:bg-red-600
          "
          @click="onDone"
          :loading="loading"
        >
          Terminé
        </AppButton>
      </div>
    </div>
  </PopupModal>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';

import { CartItem, ProductSize, Supplement } from '@/types/models';
import PopupModal from '@/components/shared/PopupModal.vue';
import ProductSupplements from '@/components/product/ProductSupplements.vue';
import ProductQuantityInput from '@/components/product/ProductQuantityInput.vue';
import ProductSizeInput from '@/components/product/ProductSizeInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useCart from '@/hooks/cart/use-cart';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { PopupModal, ProductSupplements, ProductQuantityInput, ProductSizeInput, AppButton },
  emits: ['on-done'],
  props: {
    item: {
      type: Object as PropType<CartItem>,
      required: true,
    },
  },
  setup(props) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const size = ref<ProductSize>(props.item.size);
    const quantity = ref(props.item.quantity);
    const selectedSupplements = ref<Supplement[]>(props.item.supplements || []);
    const { showToast } = useToast();
    const { updateCartProduct, loading, errors } = useCart().useUpdateCartProduct();

    watch(
      () => props.item.quantity,
      (newQuantity) => {
        if (newQuantity) {
          quantity.value = newQuantity;
        }
      }
    );

    watch(
      () => props.item.size,
      (newSize) => {
        if (newSize) {
          size.value = newSize;
        }
      }
    );

    const onDone = async () => {
      if (props.item.product?.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
        return;
      }

      await updateCartProduct({
        id: props.item.id,
        quantity: quantity.value,
        size: size.value,
        supplements: selectedSupplements.value,
      });

      if (errors.value.length) {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      } else {
        showToast({
          message: 'La mise à jour a réussi',
          type: 'link',
          link: '/checkout',
          linkText: 'Finaliser votre commande',
        });
        popup.value?.close();
      }
    };

    const onCancel = () => {
      size.value = props.item.size;
      quantity.value = props.item.quantity;
      selectedSupplements.value = props.item.supplements || [];

      if (popup.value?.show) {
        popup.value?.close();
      }
    };

    return {
      popup,
      size,
      quantity,
      selectedSupplements,
      loading,
      onDone,
      onCancel,
      getLowestPrice,
    };
  },
});
</script>
