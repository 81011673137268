import formatCartItem from '@/lib/formatCartItem';
import { CartItem, ProductSize, Supplement } from '@/types/models';
import { CART_ITEMS } from './constants';
import { useRequest } from '@/lib/http';
import { getCartState } from './use-cart';
import formatMoney from '@/lib/formatMoney';
import { getSupplements } from './cart-add-offer';

export const openCart = (): void => {
  const state = getCartState();
  state.cartOpen = true;
};

export const closeCart = (): void => {
  const state = getCartState();
  state.cartOpen = false;
};

export const clearCart = (): void => {
  const state = getCartState();
  state.items = [];
  saveToLocalStorage(state.items);
};

export const mergeCartItems = (localItems: CartItem[], remoteItems: CartItem[]): CartItem[] => {
  const cartItems = localItems.concat(remoteItems);
  const cartItemsDict: Record<string, number> = {};

  for (let i = 0; i < cartItems.length; i++) {
    const key = generateMergeKey(cartItems[i]);

    if (Object.prototype.hasOwnProperty.call(cartItemsDict, key)) {
      cartItems.splice(i, 1);
      i--;
    } else {
      cartItemsDict[key] = 1;
    }
  }

  return cartItems;
};

const generateMergeKey = (cartItem: CartItem) => {
  const supplements = cartItem.supplements.length
    ? cartItem.supplements.map(({ id }) => `${id}`).join('-')
    : 'no-supplements';
  const id = cartItem.product
    ? `product:${cartItem.product.id}`
    : `offer:${cartItem.offer_item?.id}`;
  const key = `cart:${id}:${cartItem.size.name}:${supplements}`;

  return key;
};

export const syncCartItems = async (): Promise<void> => {
  const state = getCartState();
  const { request } = useRequest({ authClient: true });

  const items = state.items
    .filter((item) => item.sync)
    .map((item) => ({
      product: item.product?.id,
      offerItem: item.offer_item
        ? {
            ...item.offer_item,
            products: item.offer_item.products.map((product) => ({
              id: product.id,
              name: product.name,
              supplements: getSupplements([product]),
            })),
          }
        : null,
      quantity: item.quantity,
      size: item.size,
      supplements: item.supplements || [],
    }));

  if (!items.length) return;

  const data = await request<CartItem[]>({ url: `/cart-items/sync`, method: 'POST', data: items });

  if (data) {
    // Sync cart item ids;
    state.items = data.map(formatCartItem);
    state.itemsSynced = true;
    saveToLocalStorage(state.items);
  }
};

type saveOptions = { formatItem?: boolean; setSync?: boolean };
type SaveCartItem = (cartItem: CartItem, options?: saveOptions) => void;

export const saveCartItem: SaveCartItem = (cartItem, options = { formatItem: true }) => {
  const state = getCartState();

  if (options.formatItem) cartItem = formatCartItem(cartItem);
  if (options.setSync) cartItem.sync = true;

  const index = state.items.findIndex(({ product, offer_item, size, supplements }) => {
    let sameItem = false;

    if (product) {
      sameItem = product.id === cartItem.product?.id && size.name === cartItem.size.name;
    }

    if (offer_item) {
      sameItem = offer_item.id === cartItem.offer_item?.id;
    }

    if (!sameItem) return false;

    if (supplements.length !== cartItem.supplements.length) return false;

    return true;
  });

  if (index === -1) {
    state.items.unshift(cartItem);
    saveToLocalStorage(state.items);
    return;
  }

  if (!isSameSupplements(state.items[index].supplements, cartItem.supplements)) {
    state.items.unshift(cartItem);
    saveToLocalStorage(state.items);
    return;
  }

  if (options.setSync) {
    state.items[index].quantity += cartItem.quantity;
  } else {
    state.items[index].quantity = cartItem.quantity;
  }

  saveToLocalStorage(state.items);
};

const isSameSupplements = (sourceSupps: Supplement[] = [], targetSupps: Supplement[] = []) => {
  if (sourceSupps.length !== targetSupps.length) return false;

  const supplementsCounter: Record<number, number> = {};

  sourceSupps.forEach((supp) => {
    supplementsCounter[supp.id] = 1;
  });

  for (let index = 0; index < targetSupps.length; index++) {
    const supp = targetSupps[index];

    if (!supplementsCounter[supp.id]) return false;
  }

  return true;
};

export const saveToLocalStorage = (items: CartItem[]): void => {
  localStorage.setItem(CART_ITEMS, JSON.stringify(items));
};

export const getFromLocalStorage = (): CartItem[] => {
  let cartItems: CartItem[] = [];

  const data = localStorage.getItem(CART_ITEMS);
  if (data) {
    const localCartItems = JSON.parse(data) as CartItem[];
    if (Array.isArray(localCartItems)) {
      cartItems = localCartItems;
    }
  }

  return cartItems;
};

export const sumCartItemPrice = (cartItem: CartItem, format = true): number | string => {
  const sum = cartItem.size.price;
  return format ? formatMoney(sum) : sum;
};

export const findDuplicateIndex = (
  item: CartItem,
  newSize: ProductSize,
  newSupps: Supplement[]
): number => {
  const state = getCartState();

  const index = state.items.findIndex((predicate) => {
    const sameCartItem = predicate.id === item.id;
    const sameProduct = predicate.product?.id === item.product?.id;
    const sameSize = predicate.size.name === newSize.name;
    return !sameCartItem && sameProduct && sameSize;
  });

  if (index === -1) return -1;

  if (!isSameSupplements(state.items[index].supplements, newSupps)) {
    return -1;
  }
  return index;
};
