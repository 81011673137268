<template>
  <AppInput
    type="select"
    label="Choisissez la taille"
    :srOnlyLabel="true"
    @update:modelValue="onSizeChange"
  >
    <option
      v-for="itemSize in sizes"
      :key="itemSize.name"
      :value="itemSize.name"
      :selected="itemSize.name === selectedSize.name"
    >
      {{ itemSize.name }}
    </option>
  </AppInput>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

import { ProductSize } from '@/types/models';
import AppInput from '@/components/shared/AppInput.vue';

export default defineComponent({
  components: { AppInput },
  emits: ['update:size'],
  props: {
    size: {
      type: Object as PropType<ProductSize>,
      required: true,
      default: () => ({}),
    },
    sizes: {
      type: Array as PropType<ProductSize[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selectedSize = ref(props.size);

    const onSizeChange = (newSize: string) => {
      const size = props.sizes.find((size) => size.name === newSize);

      if (size) {
        emit('update:size', size);
      }
    };

    return {
      selectedSize,
      onSizeChange,
    };
  },
});
</script>

<style></style>
