import { getUserState } from './use-user';

export const shouldUpdateUserData = (): boolean => {
  const { user } = getUserState();

  if (!user) return false;

  if (
    !user.address_line1 ||
    !user.address_line2 ||
    !user.city ||
    !user.postal_code ||
    !user.phone
  ) {
    return true;
  }

  return false;
};
