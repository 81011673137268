
import { defineComponent, PropType } from 'vue';
import { ResponseError } from '@/lib/formatErrors';

export default defineComponent({
  props: {
    errorkey: {
      type: String as PropType<string | null>,
      default: null,
    },
    errors: {
      type: Array as PropType<ResponseError[]>,
      default: () => [],
    },
  },
});
