import { Ref } from 'vue';

import { Restaurant } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { getRestaurantState } from './use-restaurant';

export const useGetRestuarant: UseGetRestuarant = () => {
  const state = getRestaurantState();
  const { errors, loading, request } = useRequest();

  const getRestuarant = async () => {
    if (state.restaurant || state.fetchingRestaurant) return;

    state.fetchingRestaurant = true;

    const data = await request<Restaurant>({ url: `/restaurant` });

    if (data) {
      state.restaurant = data;
    }

    state.fetchingRestaurant = false;
  };

  return { errors, loading, getRestuarant };
};

export type UseGetRestuarant = () => {
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
  getRestuarant(): Promise<void>;
};
