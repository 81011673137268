import axios from 'axios';

export type ResponseError = { id: string; message: string };
type ResponseErrorObject = { errors: Record<string, string[]> };
export type ResponseErrors = [{ messages: ResponseError[] }];

function formatErrorsArray(data: ResponseErrors): ResponseError[] {
  return data.map(({ messages }) => {
    const error = {} as ResponseError;
    messages.forEach(({ id, message }) => {
      error.id = id;
      error.message = message;
    });
    return error;
  });
}

function formatErrorsObject(data: ResponseErrorObject): ResponseError[] {
  return Object.keys(data.errors).map((key) => {
    const error = {} as ResponseError;
    data.errors[key].forEach((message) => {
      error.id = key;
      error.message = message;
    });
    return error;
  });
}

export default function formatErrors(error: Error): ResponseError[] {
  let errors: ResponseError[] = [
    { id: 'error', message: `Quelque chose s'est passé, veuillez réessayer plus tard` },
  ];

  // Something unexpected happened
  if (!axios.isAxiosError(error)) {
    return errors;
  }

  // Something happened in setting up the request that triggered an Error
  if (!error.response && !error.request) {
    return errors;
  }

  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error.response) {
    const { data } = error.response;

    if (!data.data) {
      if (error.response.status === 401) {
        errors = [{ id: 'error', message: `Vous ne pouvez pas effectuer cette action` }];
      } else {
        errors = [{ id: 'error', message: data.message }];
      }

      return errors;
    }

    if (Array.isArray(data.data)) {
      errors = formatErrorsArray(data.data);
    } else {
      errors = formatErrorsObject(data.data);
    }

    return errors;
  }

  // The request was made but no response was received
  // `error.request` is an instance of XMLHttpRequest in the browser
  if (error.request) {
    errors = [{ id: 'error', message: `${error.message}: Veuillez réessayer plus tard` }];
    return errors;
  }

  return errors;
}
