import { Ref } from 'vue';

import { Product, Wishlist } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { getWishlistState } from './use-wishlist';

export const useAddWishlistItem: UseAddWishlistItem = () => {
  const state = getWishlistState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const addWishlistItem = async (product: Product) => {
    const payload = { productId: product.id };
    const data = await request<Wishlist>({ url: `/wishlists`, method: 'POST', data: payload });

    if (data) {
      state.wishlist?.products.unshift(product);
    }
  };

  return { loading, errors, addWishlistItem };
};

export type UseAddWishlistItem = () => {
  addWishlistItem(product: Product): void;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
