<template>
  <PopupModal
    ref="popup"
    variant="coverScreen"
    class="left-4 right-4 bottom-4 z-50 sm:left-auto"
    :showBackdrop="false"
    :center="false"
  >
    <div>
      <div
        v-for="toast in toasts"
        :key="toast.id"
        :class="[toast.type === 'failure' ? 'bg-red-400' : 'bg-green-400']"
        class="p-4 mt-2 relative rounded-md shadow-md text-white"
      >
        <p>
          {{ toast.message }}
          <router-link
            v-if="toast.link && toast.type === 'link'"
            :to="toast.link"
            class="font-semibold border-b border-gray-500"
          >
            {{ toast.linkText }}
          </router-link>
        </p>

        <button
          class="absolute -top-2 -right-2 bg-white rounded-full shadow-md"
          aria-label="Fermer"
          @click="close(toast)"
        >
          <img
            src="@/assets/icons/close.svg"
            alt="close icon"
            width="24"
            height="24"
            class="w-6 h-6 text-white text-current"
          />
        </button>
      </div>
    </div>
  </PopupModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import PopupModal from './PopupModal.vue';
import { Toast } from '@/hooks/use-toast';

export default defineComponent({
  components: { PopupModal },
  setup() {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const toasts = ref<Toast[]>([]);

    const open = (duration = 5000) => {
      popup.value?.open();

      if (duration > 0) {
        toasts.value.forEach((toast) => {
          setTimeout(() => close(toast), duration);
        });
      }
    };

    const close = (toast: Toast) => {
      const index = toasts.value.findIndex((item) => item.id === toast.id);
      if (index === -1) return;
      toasts.value.splice(index, 1);

      if (!toasts.value.length) popup.value?.close();
    };

    const addToast = (toast: Toast) => {
      toast.id = toasts.value.length + 1;
      toasts.value.unshift(toast);
    };

    return {
      popup,
      toasts,
      open,
      close,
      addToast,
    };
  },
});
</script>
