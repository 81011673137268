<template>
  <transition name="fade">
    <div
      v-show="showAuthModal"
      @click="closeBackdrop"
      class="fixed z-40 inset-0 flex items-center justify-center bg-black/60"
    >
      <div class="relative w-11/12 top-4 rounded-lg bg-white sm:w-96" id="authContainer">
        <button
          class="p-2 bg-white rounded-full shadow-md absolute -top-3 -right-3 group"
          @click="closeAuthModal"
        >
          <span class="sr-only">Masquer la modalité</span>
          <XIcon class="w-4 h-4 group-hover:text-primary transition-colors" />
        </button>
        <div class="py-14 px-4 max-h-screen overflow-y-auto sm:px-6">
          <SignIn
            v-show="authAction === 'signIn'"
            :getRecaptchaToken="getRecaptchaToken"
            :captchaLoading="captchaLoading"
          />
          <SignUp
            v-show="authAction === 'signUp'"
            :getRecaptchaToken="getRecaptchaToken"
            :captchaLoading="captchaLoading"
          />
          <ForgotPassword
            v-show="authAction === 'resetPassword'"
            :getRecaptchaToken="getRecaptchaToken"
            :captchaLoading="captchaLoading"
          />
          <ForgotPasswordDone v-show="authAction === 'resetPasswordDone'" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { XIcon } from '@heroicons/vue/outline';
import { useReCaptcha } from 'vue-recaptcha-v3';

import useAuth from '@/hooks/auth/use-auth';
import useToast from '@/hooks/use-toast';
import SignIn from './SignIn.vue';
import SignUp from './SignUp.vue';
import ForgotPassword from './ForgotPassword.vue';
import ForgotPasswordDone from './ForgotPasswordDone.vue';

export default defineComponent({
  components: { SignIn, SignUp, ForgotPassword, ForgotPasswordDone, XIcon },
  setup() {
    const captchaLoading = ref(true);
    const captcha = useReCaptcha();
    const { authAction, showAuthModal, closeAuthModal } = useAuth();
    const { showToast } = useToast();

    const closeBackdrop = ({ target }: Event) => {
      const container = document.getElementById('authContainer');
      if (container !== target && !container?.contains(target as Node)) {
        closeAuthModal();
        captcha?.instance.value?.hideBadge();
      }
    };

    const getRecaptchaToken = async () => {
      let token = null;
      captchaLoading.value = true;

      try {
        token = await captcha?.executeRecaptcha('submit');

        if (!token) {
          showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
        }
      } catch {
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
      } finally {
        captchaLoading.value = false;
      }

      return token;
    };

    onMounted(async () => {
      try {
        captchaLoading.value = true;

        await captcha?.recaptchaLoaded();
      } catch {
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
      } finally {
        captchaLoading.value = false;
      }
    });

    return {
      showAuthModal,
      authAction,
      closeBackdrop,
      closeAuthModal,
      getRecaptchaToken,
      captchaLoading,
    };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
