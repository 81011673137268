
import { defineComponent } from 'vue';
import { ChatIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import ContactInfoSkeleton from '@/components/skeleton/ContactInfoSkeleton.vue';
import FooterCategoriesSkeleton from '@/components/skeleton/FooterCategoriesSkeleton.vue';
import useRestaurant from '@/hooks/restaurant/use-restaurant';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';
import useCategories from '@/hooks/categories/use-categories';

export default defineComponent({
  components: {
    ChatIcon,
    PhoneIcon,
    LocationMarkerIcon,
    Skeleton,
    ContactInfoSkeleton,
    FooterCategoriesSkeleton,
  },
  setup() {
    const { restaurant, fetchingRestaurant } = useRestaurant();
    const { formattedBusinessHours } = useBusinessHours();
    const { categories, fetchingCategories, useGetCategories } = useCategories();
    const { getCategories } = useGetCategories();

    getCategories();

    return {
      restaurant,
      fetchingRestaurant,
      formattedBusinessHours,
      categories,
      fetchingCategories,
    };
  },
});
