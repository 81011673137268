import { reactive, ToRefs, toRefs } from 'vue';

import { Offer } from '@/types/models';
import { useAllOffers, UseAllOffers } from './all-offers';
import { useFeaturedOffers, UseFeaturedOffers } from './use-featured-offers';

const state: State = reactive({
  offers: [],
  featuredOffers: [],
  count: undefined,
});

export const getOffersState = (): State => state;

export default function useOffers(): Result {
  return {
    ...toRefs(state),
    useAllOffers,
    useFeaturedOffers,
  };
}

interface State {
  offers: Offer[];
  featuredOffers: Offer[];
  count: number | undefined;
}

interface Result extends ToRefs<State> {
  useAllOffers: UseAllOffers;
  useFeaturedOffers: UseFeaturedOffers;
}
