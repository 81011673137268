
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { ArrowCircleRightIcon } from '@heroicons/vue/outline';

import CartItem from './CartItem.vue';
import closeOnOutsideClick from '@/lib/closeOnOutsideClick';
import useCart from '@/hooks/cart/use-cart';
import useAuth from '@/hooks/auth/use-auth';

export default defineComponent({
  components: { CartItem, ArrowCircleRightIcon },
  setup() {
    const router = useRouter();
    const { isSignedIn, openAuthModal } = useAuth();
    const state = useCart();

    closeOnOutsideClick(state.cartOpen, '#cart');

    const navigateToCheckout = () => {
      if (isSignedIn.value) {
        router.push({ name: 'Checkout' });
      } else {
        if (state.count.value > 0) {
          openAuthModal('signIn', { action: navigateToCheckout });
        }
      }

      state.closeCart();
    };

    return {
      ...state,
      navigateToCheckout,
      isSignedIn,
    };
  },
});
