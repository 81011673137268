<template>
  <div>
    <div v-for="error in errors" :key="error.id" class="mt-1">
      <p v-if="error.id.includes(errorkey || 'error')" class="text-sm text-red-400">
        {{ error.message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ResponseError } from '@/lib/formatErrors';

export default defineComponent({
  props: {
    errorkey: {
      type: String as PropType<string | null>,
      default: null,
    },
    errors: {
      type: Array as PropType<ResponseError[]>,
      default: () => [],
    },
  },
});
</script>
