import { Product } from '@/types/models';

const getLowestPrice = (product: Product): string => {
  if (!product.variations) return product.formattedPrice;

  const productSize = product.variations.sizes.reduce((a, b) => {
    const min = Math.min(a.price, b.price);

    if (a.price === min) return a;
    else return b;
  });

  return productSize.formattedPrice;
};

export default getLowestPrice;
