<template>
  <div class="flex items-center space-x-2">
    <button
      class="text-sm border rounded-full transform"
      :class="[
        variant === 'big' ? 'w-10 h-10' : 'w-7 h-7',
        disabledMinusBtn || loading
          ? 'border-gray-400 cursor-not-allowed sm:border-gray-600'
          : ' border-gray-600 hover:scale-110',
      ]"
      @click="onQuantityChange(quantity - 1)"
      :disabled="disabledMinusBtn || loading"
    >
      -
    </button>
    <span>{{ quantity }}</span>
    <button
      class="text-sm border rounded-full transform"
      @click="onQuantityChange(quantity + 1)"
      :class="[
        variant === 'big' ? 'w-10 h-10' : 'w-7 h-7',
        loading
          ? 'border-gray-400 cursor-not-allowed sm:border-gray-600'
          : ' border-gray-600 hover:scale-110',
      ]"
      :disabled="loading"
    >
      +
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  emits: ['update:quantity'],
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'big',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const disabledMinusBtn = computed(() => props.quantity <= 1);

    const onQuantityChange = (quantity: number) => {
      emit('update:quantity', quantity);
    };

    return {
      disabledMinusBtn,
      onQuantityChange,
    };
  },
});
</script>
