
import { defineComponent } from 'vue';
import AppButton from '@/components/shared/AppButton.vue';
import useAuth from '@/hooks/auth/use-auth';

export default defineComponent({
  components: { AppButton },
  setup() {
    const { closeAuthModal } = useAuth();

    return {
      closeAuthModal,
    };
  },
});
