
import { defineComponent, PropType } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import DisplayError from './DisplayError.vue';

export default defineComponent({
  components: { DisplayError },
  emits: ['update:checked'],
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    errors: {
      type: Array as PropType<ResponseError[]>,
      default: () => [],
    },
  },
});
