import { Product, ProductImage } from '@/types/models';
import { getBackendURL } from './env';
import formatMoney from './formatMoney';

const backendURL = getBackendURL();

export function formatImage(image?: ProductImage): ProductImage {
  if (!image) {
    return {
      url: 'https://fakeimg.pl/350x200/?text=%22Image%20d%27espace%20r%C3%A9serv%C3%A9%22',
      alternativeText: "Image d'espace réservé",
      name: "Image d'espace réservé",
    };
  }

  return {
    ...image,
    url: `${backendURL}${image?.url}`,
  };
}

const formatVariations = (variations: Product['variations']) => {
  if (!variations) return;

  return {
    sizes: variations.sizes.map((size) => ({
      ...size,
      price: Number(size.price),
      formattedPrice: formatMoney(Number(size.price)),
    })),
  };
};

export default function formatProduct(product: Product): Product {
  return {
    ...product,
    price: Number(product.price),
    formattedPrice: formatMoney(Number(product.price)),
    image: formatImage(product.image),
    variations: formatVariations(product.variations),
  };
}
