import { reactive, ToRefs, toRefs } from 'vue';

import { Restaurant } from '@/types/models';
import { useGetRestuarant, UseGetRestuarant } from './restaurant-get';

const state: State = reactive({
  restaurant: null,
  fetchingRestaurant: false,
});

export const getRestaurantState = (): State => state;

const useRestaurant: UseRestaurant = () => {
  return {
    ...toRefs(state),
    useGetRestuarant,
  };
};

export default useRestaurant;

type State = {
  restaurant: Restaurant | null;
  fetchingRestaurant: boolean;
};

type UseRestaurant = () => ToRefs<State> & {
  useGetRestuarant: UseGetRestuarant;
};
