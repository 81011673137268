
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    loadingIndicatorColor: {
      type: String,
      default: 'text-white',
    },
  },
});
