import { computed, reactive, ToRefs, toRefs } from 'vue';

import { CartItem } from '@/types/models';
import formatMoney from '@/lib/formatMoney';
import { openCart, closeCart, clearCart, sumCartItemPrice } from './utils';
import useGetCartItems, { UseGetCartItems } from './cart-get-items';
import useAddCartProduct, { UseAddCartProduct } from './cart-add-product';
import useAddCartOffer, { UseAddCartOffer } from './cart-add-offer';
import useRemoveCartItem, { UseRemoveCartItem } from './cart-remove-item';
import useUpdateCartProduct, { UseUpdateCartProduct } from './cart-update-product';
import useUpdateCartOffer, { UseUpdateCartOffer } from './cart-update-offer';

const state: State = reactive({
  cartOpen: false,
  items: [],
  itemsSynced: false,
  count: computed(() => state.items.length),
  subTotal: computed(() =>
    state.items.reduce((sum, item) => {
      const itemPrice = sumCartItemPrice(item, false) as number;

      return sum + itemPrice * item.quantity;
    }, 0)
  ),
  formattedSubTotal: computed(() => formatMoney(Number(state.subTotal))),
});

export const getCartState = (): State => state;

const useCart: UseCart = () => ({
  ...toRefs(state),
  openCart,
  closeCart,
  clearCart,
  useGetCartItems,
  useAddCartProduct,
  useAddCartOffer,
  useRemoveCartItem,
  sumCartItemPrice,
  useUpdateCartProduct,
  useUpdateCartOffer,
});

export default useCart;

type State = {
  cartOpen: boolean;
  items: CartItem[];
  itemsSynced: boolean;
  count: number;
  subTotal: number;
  formattedSubTotal: string;
};

type UseCart = () => ToRefs<State> & {
  openCart(): void;
  closeCart(): void;
  clearCart(): void;
  sumCartItemPrice: typeof sumCartItemPrice;
  useGetCartItems: UseGetCartItems;
  useAddCartProduct: UseAddCartProduct;
  useAddCartOffer: UseAddCartOffer;
  useRemoveCartItem: UseRemoveCartItem;
  useUpdateCartProduct: UseUpdateCartProduct;
  useUpdateCartOffer: UseUpdateCartOffer;
};
