<template>
  <div class="flex flex-col justify-center">
    <h3 class="text-3xl font-heading text-center">Vérifiez votre e-mail</h3>
    <h4 class="mt-4 text-sm text-center text-gray-700">
      Si un compte existe pour cet e-mail, vous recevrez un e-mail contenant des instructions pour
      réinitialiser votre mot de passe. S'il n'arrive pas, assurez-vous de vérifier votre dossier de
      courrier indésirable.
    </h4>

    <AppButton class="w-full mt-4" @click="closeAuthModal">Terminé</AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppButton from '@/components/shared/AppButton.vue';
import useAuth from '@/hooks/auth/use-auth';

export default defineComponent({
  components: { AppButton },
  setup() {
    const { closeAuthModal } = useAuth();

    return {
      closeAuthModal,
    };
  },
});
</script>
