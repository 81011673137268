
import { defineComponent, PropType, ref, watch } from 'vue';

import { CartItem, ProductSize, Supplement } from '@/types/models';
import PopupModal from '@/components/shared/PopupModal.vue';
import ProductSupplements from '@/components/product/ProductSupplements.vue';
import ProductQuantityInput from '@/components/product/ProductQuantityInput.vue';
import ProductSizeInput from '@/components/product/ProductSizeInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useCart from '@/hooks/cart/use-cart';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { PopupModal, ProductSupplements, ProductQuantityInput, ProductSizeInput, AppButton },
  emits: ['on-done'],
  props: {
    item: {
      type: Object as PropType<CartItem>,
      required: true,
    },
  },
  setup(props) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const size = ref<ProductSize>(props.item.size);
    const quantity = ref(props.item.quantity);
    const selectedSupplements = ref<Supplement[]>(props.item.supplements || []);
    const { showToast } = useToast();
    const { updateCartProduct, loading, errors } = useCart().useUpdateCartProduct();

    watch(
      () => props.item.quantity,
      (newQuantity) => {
        if (newQuantity) {
          quantity.value = newQuantity;
        }
      }
    );

    watch(
      () => props.item.size,
      (newSize) => {
        if (newSize) {
          size.value = newSize;
        }
      }
    );

    const onDone = async () => {
      if (props.item.product?.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
        return;
      }

      await updateCartProduct({
        id: props.item.id,
        quantity: quantity.value,
        size: size.value,
        supplements: selectedSupplements.value,
      });

      if (errors.value.length) {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      } else {
        showToast({
          message: 'La mise à jour a réussi',
          type: 'link',
          link: '/checkout',
          linkText: 'Finaliser votre commande',
        });
        popup.value?.close();
      }
    };

    const onCancel = () => {
      size.value = props.item.size;
      quantity.value = props.item.quantity;
      selectedSupplements.value = props.item.supplements || [];

      if (popup.value?.show) {
        popup.value?.close();
      }
    };

    return {
      popup,
      size,
      quantity,
      selectedSupplements,
      loading,
      onDone,
      onCancel,
      getLowestPrice,
    };
  },
});
