import { User } from '@/types/models';
import { TOKEN_NAME } from './constants';
import { AfterAuthAction, AuthAction, getAuthState } from './use-auth';
import { getUserState } from '@/hooks/user/use-user';

export const openAuthModal = (
  authAction: AuthAction,
  afterAuthAction = {} as AfterAuthAction
): void => {
  const state = getAuthState();

  if (afterAuthAction.action) {
    state.afterAuthAction.action = afterAuthAction.action;
  }

  state.showAuthModal = true;
  state.authAction = authAction;
};

export const closeAuthModal = (): void => {
  const state = getAuthState();

  state.showAuthModal = false;
};

export const changeAuthAction = (authAction: AuthAction): void => {
  const state = getAuthState();

  state.authAction = authAction;
};

export const saveUserLocally = (user: User, token?: string): void => {
  const authState = getAuthState();
  const userState = getUserState();

  if (token) {
    authState.token = token;
    localStorage.setItem(TOKEN_NAME, token);
  }
  userState.user = user;
};

export const removeUserLocally = (): void => {
  const authState = getAuthState();
  const userState = getUserState();

  authState.token = null;
  userState.user = null;
  localStorage.removeItem(TOKEN_NAME);
};
