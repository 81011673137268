
import { defineComponent, PropType, ref } from 'vue';

import { Supplement } from '@/types/models';
import PopupModal from '@/components/shared/PopupModal.vue';
import ProductSupplements from '@/components/product/ProductSupplements.vue';
import AppButton from '@/components/shared/AppButton.vue';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    PopupModal,
    ProductSupplements,
    AppButton,
  },
  emits: ['supplements:add'],
  props: {
    supplements: {
      type: Object as PropType<Supplement[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const selectedSupplements = ref<Supplement[]>([]);
    const { showToast } = useToast();

    const clearSelections = () => {
      selectedSupplements.value = [];

      if (popup.value?.show) {
        popup.value?.close();
      }
    };

    const onDone = async () => {
      if (props.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
      } else {
        emit('supplements:add', selectedSupplements.value);
        popup.value?.close();
      }
    };

    return {
      popup,
      selectedSupplements,
      onDone,
      clearSelections,
    };
  },
});
