import { computed, reactive, toRefs, ToRefs } from 'vue';

import { User } from '@/types/models';
import { openAuthModal, closeAuthModal, changeAuthAction } from './utils';
import { signOut } from './signOut';
import { useSignIn, UseSignIn } from './signIn';
import { useSignUp, UseSignUp } from './signUp';
import { useForgotPassword, UseForgotPassword } from './forgotPassword';
import { useResetPassword, UseResetPassword } from './resetPassword';
import useUser from '@/hooks/user/use-user';

const { user } = useUser();

const state: State = reactive({
  showAuthModal: false,
  authAction: 'signIn',
  token: null,
  isSignedIn: computed(() => user.value !== null),
  loadingUser: false,
  afterAuthAction: {
    action: undefined,
  },
});

export const getAuthState = (): State => state;

const useAuth: UseAuth = () => ({
  ...toRefs(state),
  openAuthModal,
  closeAuthModal,
  changeAuthAction,
  signOut,
  useSignIn,
  useSignUp,
  useForgotPassword,
  useResetPassword,
});

export default useAuth;

type State = {
  showAuthModal: boolean;
  authAction: AuthAction;
  token: string | null;
  isSignedIn: boolean;
  loadingUser: boolean;
  afterAuthAction: AfterAuthAction;
};

type UseAuth = () => ToRefs<State> & {
  openAuthModal(authAction: AuthAction, action?: AfterAuthAction): void;
  closeAuthModal(): void;
  changeAuthAction(authAction: AuthAction): void;
  signOut(): void;
  useSignIn: UseSignIn;
  useSignUp: UseSignUp;
  useForgotPassword: UseForgotPassword;
  useResetPassword: UseResetPassword;
};

export type AuthAction = 'signIn' | 'signUp' | 'resetPassword' | 'resetPasswordDone';
export type AuthResponse = { jwt: string; user: User };
export type AfterAuthAction = { action?<T>(): T | void };
