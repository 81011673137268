<template>
  <div class="p-4 pt-0 animate-pulse">
    <div class="mt-4 space-y-4">
      <div class="flex space-x-2">
        <div class="w-6 h-6 rounded-full bg-gray-300" />
        <div class="w-36 h-6 rounded-xl bg-gray-300" />
      </div>
      <div class="flex space-x-2">
        <div class="w-6 h-6 rounded-full bg-gray-300" />
        <div class="w-36 h-6 rounded-xl bg-gray-300" />
      </div>
      <div class="flex space-x-2">
        <div class="w-6 h-6 rounded-full bg-gray-300" />
        <div class="w-36 h-6 rounded-xl bg-gray-300" />
      </div>
    </div>
  </div>
</template>
