import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { setAuthToken, useRequest } from '@/lib/http';
import { saveUserLocally } from './utils';
import { AuthResponse } from './use-auth';

export const useSignUp: UseSignUp = () => {
  const { errors, loading, request } = useRequest();

  const signUp = async (payload: SignUpPayload) => {
    const data = await request<AuthResponse>({
      url: `/auth/local/register`,
      method: 'POST',
      data: payload,
    });

    if (data) {
      saveUserLocally(data.user, data.jwt);
      setAuthToken(data.jwt);
    }
  };

  return {
    signUp,
    loading,
    errors,
  };
};

export type UseSignUp = () => {
  signUp(payload: SignUpPayload): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type SignUpPayload = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  captchaToken: string;
};
