function checkEnv(env: string | undefined, name: string) {
  if (!env) {
    throw new Error(`Please define the ${name} environment variable inside .env.local`);
  }

  return env;
}

export function getBackendURL(): string {
  const env = process.env.VUE_APP_ROOT_API;
  return checkEnv(env, 'VUE_APP_ROOT_API');
}

export function getRecaptchaKey(): string {
  const env = process.env.VUE_APP_RECAPTCHA_KEY;
  return checkEnv(env, 'VUE_APP_RECAPTCHA_KEY');
}

export function getPaypalClientId(): string {
  const env = process.env.VUE_APP_PAYPAL_CLIENT_ID;
  return checkEnv(env, 'VUE_APP_PAYPAL_CLIENT_ID');
}
