
import { defineComponent, PropType, ref, watch } from 'vue';

import { Supplement } from '@/types/models';
import AppButton from '@/components/shared/AppButton.vue';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { AppButton },
  emits: ['on-done', 'update:supplements'],
  props: {
    supplements: {
      type: Array as PropType<Supplement[]>,
      required: true,
    },
    defaultSupplements: {
      type: Array as PropType<Supplement[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedSupplements = ref<Supplement[]>([...props.defaultSupplements]);
    const { showToast } = useToast();

    watch(
      () => props.defaultSupplements,
      (newSupplements) => {
        if (newSupplements) {
          selectedSupplements.value = newSupplements;
        }
      }
    );

    const selectSupplements = (supplement: Supplement) => {
      const index = selectedSupplements.value.findIndex(({ id }) => id === supplement.id);
      if (index !== -1) {
        selectedSupplements.value.splice(index, 1);
        emit('update:supplements', selectedSupplements.value);
      } else {
        // Allow only one supplement to be selected
        selectedSupplements.value = [supplement];
        emit('update:supplements', selectedSupplements.value);
      }
    };

    const isSelected = (supplement: Supplement) => {
      return !!selectedSupplements.value.find(({ id }) => id === supplement.id);
    };

    const onDone = () => {
      if (props.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
        return;
      }

      emit('on-done', selectedSupplements.value);
      clearSupplements();
    };

    const onClear = () => {
      selectedSupplements.value = [];
      emit('on-done', selectedSupplements.value);
      clearSupplements();
    };

    const clearSupplements = () => {
      if (!props.defaultSupplements.length) {
        selectedSupplements.value = [];
      }
    };

    return {
      selectSupplements,
      isSelected,
      onDone,
      onClear,
    };
  },
});
