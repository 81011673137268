
import { defineComponent, onMounted, ref } from 'vue';
import { XIcon } from '@heroicons/vue/outline';
import { useReCaptcha } from 'vue-recaptcha-v3';

import useAuth from '@/hooks/auth/use-auth';
import useToast from '@/hooks/use-toast';
import SignIn from './SignIn.vue';
import SignUp from './SignUp.vue';
import ForgotPassword from './ForgotPassword.vue';
import ForgotPasswordDone from './ForgotPasswordDone.vue';

export default defineComponent({
  components: { SignIn, SignUp, ForgotPassword, ForgotPasswordDone, XIcon },
  setup() {
    const captchaLoading = ref(true);
    const captcha = useReCaptcha();
    const { authAction, showAuthModal, closeAuthModal } = useAuth();
    const { showToast } = useToast();

    const closeBackdrop = ({ target }: Event) => {
      const container = document.getElementById('authContainer');
      if (container !== target && !container?.contains(target as Node)) {
        closeAuthModal();
        captcha?.instance.value?.hideBadge();
      }
    };

    const getRecaptchaToken = async () => {
      let token = null;
      captchaLoading.value = true;

      try {
        token = await captcha?.executeRecaptcha('submit');

        if (!token) {
          showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
        }
      } catch {
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
      } finally {
        captchaLoading.value = false;
      }

      return token;
    };

    onMounted(async () => {
      try {
        captchaLoading.value = true;

        await captcha?.recaptchaLoaded();
      } catch {
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
      } finally {
        captchaLoading.value = false;
      }
    });

    return {
      showAuthModal,
      authAction,
      closeBackdrop,
      closeAuthModal,
      getRecaptchaToken,
      captchaLoading,
    };
  },
});
