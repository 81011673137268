
import { defineComponent, PropType, ref } from 'vue';
import { XIcon } from '@heroicons/vue/outline';

export type ModalVaraint = 'coverScreen' | 'relative';

export default defineComponent({
  emits: ['on-close'],
  components: { XIcon },
  props: {
    variant: {
      type: String as PropType<ModalVaraint>,
      default: 'coverScreen',
    },
    showBackdrop: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: true,
    },
    fixBackDrop: {
      type: Boolean,
      default: true,
    },
    shouldCloseBackDrop: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(false);

    const open = () => {
      show.value = true;
    };

    const close = () => {
      show.value = false;
      emit('on-close');
    };

    const closeBackdrop = ({ target }: Event) => {
      if (!props.showBackdrop) return;

      const container = document.getElementById('popupContainer') as HTMLElement | null;

      if (container === target || !container?.contains(target as HTMLElement)) {
        close();
      }
    };

    return {
      show,
      open,
      close,
      closeBackdrop,
    };
  },
});
