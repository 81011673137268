<template>
  <router-link
    :to="href"
    class="relative hover:text-gray-800"
    :class="[href === $route.path ? 'font-semibold text-gray-800' : 'text-gray-500']"
  >
    {{ text }}
    <span v-show="href === $route.path" class="absolute -bottom-1 left-0 w-full h-0.5 bg-primary" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    href: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
  },
});
</script>
