<template>
  <article
    v-if="variant === 'small'"
    class="h-80 relative rounded-2xl shadow-md overflow-hidden sm:h-96"
  >
    <div class="h-3/5 overflow-hidden relative">
      <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
        <img
          :src="product.image?.url"
          width="340"
          height="220"
          :alt="product.name"
          class="
            w-full
            h-full
            object-cover
            transition-transform
            transform
            duration-500
            hover:scale-110
          "
        />
      </router-link>
    </div>
    <div class="h-2/5 p-4 flex flex-col">
      <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
        <h3 class="text-xl line-clamp-1 font-heading sm:text-2xl" :title="product.name">
          {{ product.name }}
        </h3>
      </router-link>
      <div class="mt-auto flex justify-between items-center">
        <p class="flex flex-col">
          <span v-if="product.variations" class="-mb-0.5 text-xs text-gray-600">A partir de</span>
          <span class="text-3xl font-display relative text-gray-900">
            {{ getLowestPrice(product) }}
          </span>
        </p>
        <div class="space-x-4">
          <AppRoundButton
            class="bg-primary hover:bg-white"
            label="Ajouter au panier"
            @click="openOptionsModal"
          >
            <PlusIcon class="h-6 w-6 text-white transition-colors group-hover:text-gray-800" />
          </AppRoundButton>

          <AppRoundButton
            class="bg-white"
            label="Ajouter à la liste de souhaits"
            :loading="loading"
            @click="addToWishlist"
            loadingIndicatorColor="text-primary"
          >
            <HeartIcon
              class="h-6 w-6 transition-colors group-hover:text-primary"
              :class="[inWishlist(product.id) ? 'text-primary' : 'text-gray-300']"
            />
          </AppRoundButton>
        </div>
      </div>
    </div>

    <ProductOptionsModal ref="productOptionsModal" :product="product" />
  </article>

  <article
    v-else
    class="
      w-full
      h-full
      flex flex-col
      rounded-tr-3xl rounded-br-3xl
      shadow-md
      md:rounded-tr-none md:flex-row
      bg-primary bg-gradient-to-tr
      from-red-500
      to-primary
      relative
    "
  >
    <div class="h-96 overflow-hidden rounded-tr-3xl md:flex-1 md:h-auto md:rounded-tr-none">
      <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
        <img
          :src="product.image?.url"
          width="340"
          height="220"
          :alt="product.name"
          class="
            w-full
            h-full
            object-cover
            transition-transform
            transform
            duration-500
            hover:scale-110
          "
        />
      </router-link>
    </div>
    <div class="p-4 pb-0 flex flex-col justify-center md:flex-1 md:p-8">
      <header class="flex items-center justify-between space-x-2">
        <h3
          class="
            text-2xl
            line-clamp-1
            font-display font-semibold
            text-white
            sm:text-3xl
            md:text-4xl
          "
          :title="product.name"
        >
          {{ product.name }}
        </h3>
        <AppRoundButton
          class="bg-white"
          label="Ajouter à la liste de souhaits"
          :loading="loading"
          @click="addToWishlist"
          loadingIndicatorColor="text-primary"
        >
          <HeartIcon
            class="h-5 w-5 transition-colors group-hover:text-primary sm:w-6 sm:h-6"
            :class="[inWishlist(product.id) ? 'text-primary' : 'text-gray-300']"
          />
        </AppRoundButton>
      </header>
      <p
        class="mt-4 text-xs text-gray-100 sm:text-sm sm:w-11/12 md:text-base md:w-10/12"
        :class="{ 'line-clamp-6 2xl:line-clamp-none': $route.name !== 'Product' }"
        :title="product.description"
      >
        {{ product.description }}
      </p>
      <div class="py-4 sm:py-8">
        <div class="space-y-2 sm:space-y-0 sm:space-x-20 sm:flex sm:items-center">
          <div class="flex flex-col space-y-4">
            <span class="font-heading text-white text-lg">Prix:</span>
            <p class="flex flex-col">
              <span v-if="product.variations" class="-mb-0.5 text-xs text-accent">
                A partir de
              </span>
              <span class="text-2xl font-semibold text-gray-800 md:text-4xl">
                {{ getLowestPrice(product) }}
              </span>
            </p>
          </div>
        </div>

        <div class="mt-8 space-y-4 md:space-y-0 md:space-x-6 flex flex-col md:flex-row">
          <AppButton
            class="
              space-x-2
              rounded-2xl
              bg-white
              hover:ring-4 hover:ring-red-200
              focus:outline-none focus:ring-4 focus:ring-red-400
            "
            @click="openOptionsModal"
          >
            <ShoppingBagIcon class="h-6 w-6" />
            <span class="ml-2">Ajouter au Panier</span>
          </AppButton>
        </div>
      </div>
    </div>

    <ProductOptionsModal v-if="useInternalModal" ref="productOptionsModal" :product="product" />
  </article>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { ShoppingBagIcon, PlusIcon } from '@heroicons/vue/outline';
import { HeartIcon } from '@heroicons/vue/solid';

import { Product } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import AppButton from '@/components/shared/AppButton.vue';
import ProductOptionsModal from './ProductOptionsModal.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useWishlist from '@/hooks/wishlist/use-wishlist';
import useAuth from '@/hooks/auth/use-auth';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    AppRoundButton,
    ProductOptionsModal,
    AppButton,
    ShoppingBagIcon,
    PlusIcon,
    HeartIcon,
  },
  emits: ['on-open-modal'],
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'big',
    },
    useInternalModal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { isSignedIn, openAuthModal } = useAuth();
    const { useAddWishlistItem, inWishlist } = useWishlist();
    const { showToast } = useToast();
    const { addWishlistItem, loading, errors: wishlistErrors } = useAddWishlistItem();

    const productOptionsModal = ref<InstanceType<typeof ProductOptionsModal>>();

    const addToWishlist = async () => {
      if (!isSignedIn.value) {
        return openAuthModal('signIn', { action: addToWishlist });
      }

      await addWishlistItem(props.product);

      if (!wishlistErrors.value.length) {
        showToast({ message: `${props.product.name} ajouté à la liste de souhaits` });
      } else {
        wishlistErrors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    const openOptionsModal = () => {
      if (props.useInternalModal) {
        productOptionsModal.value?.popup?.open();
      } else {
        emit('on-open-modal', props.product);
      }
    };

    return {
      productOptionsModal,
      loading,
      openOptionsModal,
      addToWishlist,
      inWishlist,
      getLowestPrice,
    };
  },
});
</script>
