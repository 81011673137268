
import { defineComponent, PropType } from 'vue';
import { ResponseError } from '@/lib/formatErrors';
import DisplayError from './DisplayError.vue';

export default defineComponent({
  components: { DisplayError },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    srOnlyLabel: {
      default: false,
    },
    placeholder: {
      default: '',
    },
    border: {
      default: 'rounded-lg',
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      default: 'text',
    },
    errors: {
      type: Array as PropType<ResponseError[]>,
      default: () => [],
    },
  },
  setup() {
    const baseStyles = `w-full py-2 px-3 border border-gray-light text-accent-1 focus:outline-none focus:ring-4 focus:ring-gray-light`;

    return {
      baseStyles,
    };
  },
});
