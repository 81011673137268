<template>
  <div class="grid gap-8 grid-cols-1 sm:grid-cols-2 md:gap-12 lg:grid-cols-3 2xl:grid-cols-4">
    <ProductCard
      v-show="products.length"
      v-for="product in products"
      :key="product.id"
      :product="product"
      variant="small"
    />
    <p v-show="!products.length" class="p-2">Aucun produit ...</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Product } from '@/types/models';
import ProductCard from './ProductCard.vue';

export default defineComponent({
  components: { ProductCard },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
  },
});
</script>
