
import { defineComponent, PropType } from 'vue';

import { Offer } from '@/types/models';
import OfferCard from './OfferCard.vue';

export default defineComponent({
  components: { OfferCard },
  props: {
    offers: {
      type: Array as PropType<Offer[]>,
      required: true,
    },
  },
});
