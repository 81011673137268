<template>
  <article class="relative">
    <div class="absolute -top-4 -right-4 z-10">
      <AppRoundButton
        class="bg-white shadow-md hover:bg-primary"
        label="Supprimer"
        @click.stop="removeOfferItem"
        v-if="isSelected"
      >
        <XIcon class="h-4 w-4 text-gray-800 transition-colors group-hover:text-white" />
      </AppRoundButton>
    </div>

    <div class="h-56 relative rounded-2xl shadow-md overflow-hidden sm:h-56">
      <div class="h-3/5 overflow-hidden relative">
        <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
          <img
            :src="product.image?.url"
            width="100"
            height="120"
            :alt="product.name"
            class="
              w-full
              h-full
              object-cover
              transition-transform
              transform
              duration-500
              hover:scale-110
            "
          />
        </router-link>
      </div>
      <div class="h-2/5 p-2 flex flex-col">
        <h3 class="text-lg line-clamp-1 font-heading sm:text-xl" :title="product.name">
          {{ product.name }}
        </h3>
        <div class="mt-2 space-x-4 flex justify-end items-center">
          <AppRoundButton
            class="bg-primary hover:bg-white"
            :label="isSelected ? 'Supprimer' : 'Ajouter'"
            @click.stop="addOfferItem"
          >
            <PlusIcon class="h-4 w-4 text-white transition-colors group-hover:text-gray-800" />
          </AppRoundButton>

          <AppRoundButton
            class="bg-white"
            label="Ajouter à la liste de souhaits"
            :loading="loading"
            @click.stop="addToWishlist"
            loadingIndicatorColor="text-primary"
          >
            <HeartIcon
              class="h-4 w-4 transition-colors group-hover:text-primary"
              :class="[inWishlist(product.id) ? 'text-primary' : 'text-gray-300']"
            />
          </AppRoundButton>
        </div>
      </div>

      <ProductSupplementsModal
        ref="supplementsModal"
        :supplements="product.supplements"
        @supplements:add="saveSupplements"
      />
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { PlusIcon, XIcon } from '@heroicons/vue/outline';
import { HeartIcon } from '@heroicons/vue/solid';

import { Product, Supplement } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import ProductSupplementsModal from './ProductSupplementsModal.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useWishlist from '@/hooks/wishlist/use-wishlist';
import useAuth from '@/hooks/auth/use-auth';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    AppRoundButton,
    PlusIcon,
    HeartIcon,
    XIcon,
    ProductSupplementsModal,
  },
  emits: ['product:add', 'product:remove'],
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isSignedIn, openAuthModal } = useAuth();
    const { useAddWishlistItem, inWishlist } = useWishlist();
    const { showToast } = useToast();
    const { addWishlistItem, loading, errors: wishlistErrors } = useAddWishlistItem();
    const selectedSupplements = ref<Supplement[]>([]);
    const supplementsModal = ref<InstanceType<typeof ProductSupplementsModal>>();

    const addToWishlist = async () => {
      if (!isSignedIn.value) {
        return openAuthModal('signIn', { action: addToWishlist });
      }

      await addWishlistItem(props.product);

      if (!wishlistErrors.value.length) {
        showToast({ message: `${props.product.name} ajouté à la liste de souhaits` });
      } else {
        wishlistErrors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    const saveSupplements = (supplements: Supplement[]) => {
      selectedSupplements.value = supplements;

      emit('product:add', { ...props.product, supplements: selectedSupplements.value });
    };

    const removeOfferItem = () => {
      if (props.isSelected) {
        emit('product:remove', props.product);
      }
    };

    const addOfferItem = () => {
      if (props.product.supplements.length) {
        supplementsModal.value?.popup?.open();
      } else {
        emit('product:add', props.product);
      }
    };

    return {
      loading,
      supplementsModal,
      saveSupplements,
      addOfferItem,
      removeOfferItem,
      addToWishlist,
      inWishlist,
      getLowestPrice,
    };
  },
});
</script>
