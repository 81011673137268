<template>
  <metainfo />
  <navigation />
  <authentication v-if="!userLoaded" />
  <cart />
  <router-view />
  <the-footer />
  <toast ref="toast" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useMeta } from 'vue-meta';

import { globalMetaTags } from '@/lib/metaTags';
import Navigation from '@/components/layout/Navigation.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import Cart from '@/components/cart/Cart.vue';
import Authentication from '@/components/auth/Authentication.vue';
import Toast from '@/components/shared/Toast.vue';
import useUser from './hooks/user/use-user';
import useRestaurant from '@/hooks/restaurant/use-restaurant';
import useCart from '@/hooks/cart/use-cart';
import useWishlist from '@/hooks/wishlist/use-wishlist';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { Navigation, TheFooter, Cart, Authentication, Toast },
  setup() {
    useMeta(globalMetaTags);
    const userLoaded = ref(false);
    const { fetchUser } = useUser();
    const { getRestuarant } = useRestaurant().useGetRestuarant();
    const { getCartItems } = useCart().useGetCartItems();
    const { getWishlist } = useWishlist().useGetWishlist();
    const { toast } = useToast();

    fetchUser().then((fetched) => {
      userLoaded.value = fetched;
      getCartItems();
      if (fetched) getWishlist();
    });

    getRestuarant();

    return {
      userLoaded,
      toast,
    };
  },
});
</script>
