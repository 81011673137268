import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import App from './App.vue';
import { getRecaptchaKey } from './lib/env';
import router from './router';
import './styles/index.css';

const app = createApp(App);
const metaManager = createMetaManager();

app.use(router);
app.use(metaManager);
app.use(VueReCaptcha, {
  siteKey: getRecaptchaKey(),
  loaderOptions: {
    autoHideBadge: true,
  },
});

router.isReady().then(() => app.mount('#app'));
