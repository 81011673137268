<template>
  <div class="grid gap-8 grid-cols-1 sm:grid-cols-2 md:gap-12 lg:grid-cols-3 2xl:grid-cols-4">
    <ProductCardSkeleton variant="small" v-for="(_, i) in Array.from({ length })" :key="i" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProductCardSkeleton from './ProductCardSkeleton.vue';

export default defineComponent({
  components: { ProductCardSkeleton },
  props: {
    length: {
      type: Number,
      default: 6,
    },
  },
});
</script>
