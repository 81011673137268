<template>
  <button
    class="
      px-6
      py-2
      rounded-3xl
      flex
      justify-center
      items-center
      space-x-2
      border border-gray-700
      transition-colors
    "
    :disabled="loading"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
