
import { defineComponent, PropType } from 'vue';

import { Category } from '@/types/models';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import CategoriesSkeleton from '@/components/skeleton/CategoriesSkeleton.vue';
import AppButton from '@/components/shared/AppButton.vue';
import useCategories from '@/hooks/categories/use-categories';

export default defineComponent({
  components: { AppButton, Skeleton, CategoriesSkeleton },
  emits: ['update:activeCategory'],
  props: {
    activeCategory: {
      type: Object as PropType<Category>,
      required: true,
    },
  },
  setup() {
    const { categories, useGetCategories } = useCategories();
    const { getCategories, loading } = useGetCategories();
    const btnStyles =
      'px-6 font-display capitalize font-normal rounded-xl shadow-md transition-colors hover:bg-secondary hover:text-white  md:text-lg';

    getCategories();

    return {
      btnStyles,
      categories,
      loading,
    };
  },
});
