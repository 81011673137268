
import { defineComponent, PropType, ref } from 'vue';
import { PlusIcon, ShoppingBagIcon } from '@heroicons/vue/outline';

import { Offer } from '@/types/models';
import AppRoundButton from '@/components/shared/AppRoundButton.vue';
import AppButton from '@/components/shared/AppButton.vue';
import OfferProductsModal from './OfferProductsModal.vue';

export default defineComponent({
  components: {
    AppRoundButton,
    PlusIcon,
    ShoppingBagIcon,
    OfferProductsModal,
    AppButton,
  },
  emits: ['on-open-modal'],
  props: {
    offer: {
      type: Object as PropType<Offer>,
      required: true,
    },
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'small',
    },
    useInternalModal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const offerProductsModal = ref<InstanceType<typeof OfferProductsModal>>();

    const openModal = () => {
      if (props.useInternalModal) {
        offerProductsModal.value?.popup?.open();
      } else {
        emit('on-open-modal', props.offer);
      }
    };

    return {
      offerProductsModal,
      openModal,
    };
  },
});
