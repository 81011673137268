<template>
  <div class="flex flex-col justify-center">
    <h3 class="text-3xl font-heading text-center">Réinitialiser le mot de passe</h3>
    <h4 class="mt-4 text-sm text-center text-gray-700">
      Entrez votre e-mail pour réinitialiser votre mot de passe
    </h4>
    <DisplayError :errors="errors" class="text-center mt-3" />

    <form class="mt-6 space-y-4" @submit.prevent="onSubmit">
      <AppInput id="email" label="E-mail" />
      <AppButton
        class="w-full bg-primary text-white hover:ring-red-200 focus:ring-red-200"
        type="submit"
        :loading="loading || captchaLoading"
      >
        Réinitialiser le mot de passe
      </AppButton>
    </form>
    <AppButton
      class="w-full mt-4 font-normal hover:ring-gray-light focus:ring-gray-light"
      @click="changeAuthAction('signIn')"
    >
      Annuler
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useAuth from '@/hooks/auth/use-auth';
import AppInput from '@/components/shared/AppInput.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import AppButton from '@/components/shared/AppButton.vue';

export default defineComponent({
  components: { AppInput, DisplayError, AppButton },
  props: {
    getRecaptchaToken: {
      type: Function,
      required: true,
    },
    captchaLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { changeAuthAction, useForgotPassword } = useAuth();
    const { requestResetLink, loading, errors } = useForgotPassword();

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const email = (elements.namedItem('email') as HTMLInputElement).value;

      const captchaToken = await props.getRecaptchaToken();
      if (!captchaToken) return;

      await requestResetLink({ email, captchaToken });

      if (!errors.value.length) {
        (e.target as HTMLFormElement).reset();
        changeAuthAction('resetPasswordDone');
      }
    };

    return {
      changeAuthAction,
      errors,
      loading,
      onSubmit,
    };
  },
});
</script>
