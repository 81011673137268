
import { defineComponent, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, A11y, Autoplay } from 'swiper/core';
import 'swiper/swiper.scss';

import { Offer, Product } from '@/types/models';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import ProductCardSkeleton from '@/components/skeleton/ProductCardSkeleton.vue';
import ProductCard from '@/components/product/ProductCard.vue';
import ProductOptionsModal from '@/components/product/ProductOptionsModal.vue';
import OfferCard from '@/components/offers/OfferCard.vue';
import useProducts from '@/hooks/products/use-products';
import useOffers from '@/hooks/offers/use-offers';
import OfferProductsModal from '../offers/OfferProductsModal.vue';

SwiperCore.use([Navigation, Autoplay, A11y]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    ProductCard,
    Skeleton,
    ProductCardSkeleton,
    ProductOptionsModal,
    OfferCard,
    OfferProductsModal,
  },
  setup() {
    const { featuredProducts, useFeaturedProducts } = useProducts();
    const { getFeaturedProducts, loading, errors } = useFeaturedProducts();
    const { featuredOffers, useFeaturedOffers } = useOffers();
    const { getFeaturedOffers, loading: offersLoading, errors: offerErrors } = useFeaturedOffers();
    const modalProduct = ref<Product>();
    const modalOffer = ref<Offer>();
    const productOptionsModal = ref<InstanceType<typeof ProductOptionsModal>>();
    const offerProductsModal = ref<InstanceType<typeof OfferProductsModal>>();

    const openProductModal = (product: Product) => {
      modalProduct.value = product;
      productOptionsModal.value?.popup?.open();
    };

    const openOfferModal = (offer: Offer) => {
      modalOffer.value = offer;
      offerProductsModal.value?.popup?.open();
    };

    const getFeaturedItems = async () => {
      await Promise.all([getFeaturedOffers(), getFeaturedProducts()]);
    };

    getFeaturedItems();

    return {
      openProductModal,
      openOfferModal,
      productOptionsModal,
      offerProductsModal,
      modalProduct,
      modalOffer,
      featuredProducts,
      loading,
      errors,
      featuredOffers,
      offersLoading,
      offerErrors,
      navigation: {
        nextEl: '#swiper-button-next',
        prevEl: '#swiper-button-prev',
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    };
  },
});
