
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  emits: ['update:quantity'],
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    variant: {
      type: String as PropType<'small' | 'big'>,
      default: 'big',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const disabledMinusBtn = computed(() => props.quantity <= 1);

    const onQuantityChange = (quantity: number) => {
      emit('update:quantity', quantity);
    };

    return {
      disabledMinusBtn,
      onQuantityChange,
    };
  },
});
