import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { setAuthToken, useRequest } from '@/lib/http';
import { saveUserLocally } from './utils';
import { AuthResponse } from './use-auth';

export const useSignIn: UseSignIn = () => {
  const { errors, loading, request } = useRequest();

  const signIn = async (payload: SignInPayload) => {
    const data = await request<AuthResponse>({ url: `/auth/local`, method: 'POST', data: payload });

    if (data) {
      saveUserLocally(data.user, data.jwt);
      setAuthToken(data.jwt);
    }
  };

  return {
    signIn,
    loading,
    errors,
  };
};

export type UseSignIn = () => {
  signIn(item: SignInPayload): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type SignInPayload = { identifier: string; password: string; captchaToken: string };
