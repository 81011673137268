
import { defineComponent, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';

export default defineComponent({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  emits: ['update:page'],
  props: {
    total: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    linkName: {
      type: String,
      default: '#',
    },
  },
  setup(props, { emit }) {
    const { query } = useRoute();
    const page = ref(!query.page ? 1 : +query.page <= 0 ? 1 : +query.page);
    const totalPages = ref(0);

    watchEffect(() => {
      totalPages.value = Math.ceil(props.total <= 0 ? 0 : props.total / props.itemsPerPage);
    });

    const updateCurrentPage = (newPage: number) => {
      if (newPage <= 0 || newPage > props.total) {
        return;
      }

      page.value = newPage;
      emit('update:page', page.value);
    };

    return {
      page,
      totalPages,
      updateCurrentPage,
    };
  },
});
