<template>
  <div class="min-w-[18rem] max-w-lg bg-white sm:min-w-[24rem]">
    <h3 class="mb-6 text-xl font-display text-gray-700 sm:text-2xl">Choisissez votre Base:</h3>

    <div class="space-x-2 flex flex-wrap items-center justify-evenly sm:space-x-5">
      <AppButton
        v-for="supplement in supplements"
        :key="supplement.id"
        @click="selectSupplements(supplement)"
        class="
          mb-5
          rounded-2xl
          bg-secondary
          text-white
          transition-all
          hover:bg-yellow-300
          focus:outline-none focus:ring focus:ring-yellow-500
        "
        :class="{ 'scale-105 ring ring-offset-2 ring-primary': isSelected(supplement) }"
        :disabled="loading"
      >
        {{ supplement.name }}
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';

import { Supplement } from '@/types/models';
import AppButton from '@/components/shared/AppButton.vue';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { AppButton },
  emits: ['on-done', 'update:supplements'],
  props: {
    supplements: {
      type: Array as PropType<Supplement[]>,
      required: true,
    },
    defaultSupplements: {
      type: Array as PropType<Supplement[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedSupplements = ref<Supplement[]>([...props.defaultSupplements]);
    const { showToast } = useToast();

    watch(
      () => props.defaultSupplements,
      (newSupplements) => {
        if (newSupplements) {
          selectedSupplements.value = newSupplements;
        }
      }
    );

    const selectSupplements = (supplement: Supplement) => {
      const index = selectedSupplements.value.findIndex(({ id }) => id === supplement.id);
      if (index !== -1) {
        selectedSupplements.value.splice(index, 1);
        emit('update:supplements', selectedSupplements.value);
      } else {
        // Allow only one supplement to be selected
        selectedSupplements.value = [supplement];
        emit('update:supplements', selectedSupplements.value);
      }
    };

    const isSelected = (supplement: Supplement) => {
      return !!selectedSupplements.value.find(({ id }) => id === supplement.id);
    };

    const onDone = () => {
      if (props.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
        return;
      }

      emit('on-done', selectedSupplements.value);
      clearSupplements();
    };

    const onClear = () => {
      selectedSupplements.value = [];
      emit('on-done', selectedSupplements.value);
      clearSupplements();
    };

    const clearSupplements = () => {
      if (!props.defaultSupplements.length) {
        selectedSupplements.value = [];
      }
    };

    return {
      selectSupplements,
      isSelected,
      onDone,
      onClear,
    };
  },
});
</script>
