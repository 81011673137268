import { Ref } from 'vue';

import { Product } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import formatProduct from '@/lib/formatProduct';
import { getProductsState } from './use-products';

export const useFeaturedProducts: UseFeaturedProducts = () => {
  const state = getProductsState();
  const { errors, loading, request } = useRequest();

  const getFeaturedProducts = async () => {
    if (state.featuredProducts.length) return;

    const data = await request<Product[]>({ url: `/products?featured=true` });

    if (data) {
      state.featuredProducts = data.map(formatProduct);
    }
  };

  return { loading, errors, getFeaturedProducts };
};

export type UseFeaturedProducts = () => {
  getFeaturedProducts(): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
