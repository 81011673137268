<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white px-4 py-3 flex items-center justify-between sm:px-6">
    <div class="flex-1 flex justify-between sm:hidden">
      <button
        @click="updateCurrentPage(page - 1)"
        :disabled="page <= 1"
        :class="[
          page <= 1
            ? 'bg-gray-300 border-gray-400 cursor-not-allowed'
            : 'bg-white border-gray-300  hover:bg-gray-50',
          'relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-md border text-gray-700',
        ]"
      >
        Précédent
      </button>
      <button
        @click="updateCurrentPage(page + 1)"
        :disabled="itemsPerPage * page >= total"
        :class="[
          itemsPerPage * page >= total
            ? 'bg-gray-300 border-gray-400 cursor-not-allowed'
            : 'bg-white border-gray-300 hover:bg-gray-50',
          'ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md border text-gray-700',
        ]"
      >
        Suivant
      </button>
    </div>

    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div v-if="(page - 1) * itemsPerPage + 1 < total">
        <p class="text-sm text-gray-700">
          Affichage de
          {{ ' ' }}
          <span class="font-medium">
            {{ page === 1 ? 1 : page * itemsPerPage - itemsPerPage + 1 }}
          </span>
          {{ ' ' }}
          à
          {{ ' ' }}
          <span class="font-medium">
            {{
              page === 1 ? itemsPerPage : page * itemsPerPage > total ? total : page * itemsPerPage
            }}
          </span>
          {{ ' ' }}
          sur
          {{ ' ' }}
          <span class="font-medium">{{ total }}</span>
          {{ ' ' }}
          résultats
        </p>
      </div>
      <div v-else>
        <p class="text-sm text-gray-700">Plus de livres</p>
      </div>

      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            @click="updateCurrentPage(page - 1)"
            :disabled="page <= 1"
            :class="[
              page <= 1
                ? 'bg-gray-300 border-gray-400 cursor-not-allowed'
                : 'bg-white border-gray-300  hover:bg-gray-50',
              'relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-l-md border text-gray-500',
            ]"
          >
            <span class="sr-only">Précédent</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>

          <button
            v-for="(item, idx) in new Array(totalPages)"
            :key="item"
            @click="updateCurrentPage(idx + 1)"
            aria-current="page"
            :class="[
              idx + 1 === page
                ? 'z-10 bg-red-50 border-primary text-red-600'
                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
              'relative  inline-flex items-center  px-4 border text-sm font-medium',
            ]"
          >
            <span v-if="linkName === '#'">{{ idx + 1 }}</span>
            <router-link
              v-else
              :to="{ name: linkName, query: { page: idx + 1 } }"
              aria-current="page"
            >
              {{ idx + 1 }}
            </router-link>
          </button>

          <button
            @click="updateCurrentPage(page + 1)"
            :disabled="itemsPerPage * page >= total"
            :class="[
              itemsPerPage * page >= total
                ? 'bg-gray-300 border-gray-400 cursor-not-allowed'
                : 'bg-white border-gray-300 hover:bg-gray-50',
              'relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-r-md border text-gray-500',
            ]"
          >
            <span class="sr-only">Suivant</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';

export default defineComponent({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  emits: ['update:page'],
  props: {
    total: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    linkName: {
      type: String,
      default: '#',
    },
  },
  setup(props, { emit }) {
    const { query } = useRoute();
    const page = ref(!query.page ? 1 : +query.page <= 0 ? 1 : +query.page);
    const totalPages = ref(0);

    watchEffect(() => {
      totalPages.value = Math.ceil(props.total <= 0 ? 0 : props.total / props.itemsPerPage);
    });

    const updateCurrentPage = (newPage: number) => {
      if (newPage <= 0 || newPage > props.total) {
        return;
      }

      page.value = newPage;
      emit('update:page', page.value);
    };

    return {
      page,
      totalPages,
      updateCurrentPage,
    };
  },
});
</script>
