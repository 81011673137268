import { User } from '@/types/models';
import { TOKEN_NAME } from '../auth/constants';
import { saveUserLocally } from '../auth/utils';
import { setAuthToken, useRequest } from '@/lib/http';
import { getUserState } from './use-user';

export const fetchUser = async (): Promise<boolean> => {
  const state = getUserState();

  if (state.user) return true;

  const token = localStorage.getItem(TOKEN_NAME);
  if (!token) return false;

  setAuthToken(token);
  const { request } = useRequest({ authClient: true });

  state.loadingUser = true;

  const data = await request<User>({ url: `/users/me` });

  if (data) {
    saveUserLocally(data);
    state.loadingUser = false;
    return true;
  } else {
    state.loadingUser = false;
    return false;
  }
};
