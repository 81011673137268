<template>
  <section class="px-4 py-8 sm:px-6 md:px-14">
    <h2 class="mb-12 text-4xl font-heading text-center md:text-6xl">Les Plus Populaires</h2>
    <Categories v-model:activeCategory="category" />

    <Skeleton :show="loading || productsLoading || offersLoading" class="mt-12">
      <OffersList v-if="category.slug === 'offers'" :offers="offers" />
      <ProductsList
        v-else
        :products="
          (category?.name && category.slug !== 'all' && productsByCategory) || popularProducts
        "
      />

      <template #fallback>
        <ProductListSkeleton />
      </template>
    </Skeleton>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import { Category, Product } from '@/types/models';
import Categories from '@/components/home/Categories.vue';
import ProductsList from '@/components/product/ProductsList.vue';
import useProducts from '@/hooks/products/use-products';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import ProductListSkeleton from '@/components/skeleton/ProductListSkeleton.vue';
import useCategories, { createCategory } from '@/hooks/categories/use-categories';
import useOffers from '@/hooks/offers/use-offers';
import OffersList from '../offers/OffersList.vue';

export default defineComponent({
  components: { Categories, ProductsList, Skeleton, ProductListSkeleton, OffersList },
  setup() {
    const { popularProducts, usePopularProducts } = useProducts();
    const { getPopularProducts, loading: productsLoading } = usePopularProducts();
    const { getProductsByCategory, loading } = useCategories().useGetProductsByCategory();
    const { offers, useAllOffers } = useOffers();
    const { getAllOffers, loading: offersLoading } = useAllOffers();
    const category = ref<Category>(createCategory('Tous les produits', 'all', -1));
    const productsByCategory = ref<Product[]>([]);

    watch(category, async ({ name: categoryName, slug }) => {
      if (!categoryName) return;

      if (slug === 'all') {
        getPopularProducts();
      } else if (slug === 'offers') {
        getAllOffers({ limit: 9 });
      } else {
        productsByCategory.value = await getProductsByCategory(categoryName, {
          sort: 'purchases:desc',
        });
      }
    });

    getPopularProducts();

    return {
      popularProducts,
      productsLoading,
      category,
      productsByCategory,
      loading,
      offers,
      offersLoading,
    };
  },
});
</script>
