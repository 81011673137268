
import { defineComponent, ref } from 'vue';

import PopupModal from './PopupModal.vue';
import { Toast } from '@/hooks/use-toast';

export default defineComponent({
  components: { PopupModal },
  setup() {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const toasts = ref<Toast[]>([]);

    const open = (duration = 5000) => {
      popup.value?.open();

      if (duration > 0) {
        toasts.value.forEach((toast) => {
          setTimeout(() => close(toast), duration);
        });
      }
    };

    const close = (toast: Toast) => {
      const index = toasts.value.findIndex((item) => item.id === toast.id);
      if (index === -1) return;
      toasts.value.splice(index, 1);

      if (!toasts.value.length) popup.value?.close();
    };

    const addToast = (toast: Toast) => {
      toast.id = toasts.value.length + 1;
      toasts.value.unshift(toast);
    };

    return {
      popup,
      toasts,
      open,
      close,
      addToast,
    };
  },
});
