
import { defineComponent } from 'vue';
import useAuth from '@/hooks/auth/use-auth';
import AppInput from '@/components/shared/AppInput.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import AppButton from '@/components/shared/AppButton.vue';

export default defineComponent({
  components: { AppInput, DisplayError, AppButton },
  props: {
    getRecaptchaToken: {
      type: Function,
      required: true,
    },
    captchaLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { changeAuthAction, useForgotPassword } = useAuth();
    const { requestResetLink, loading, errors } = useForgotPassword();

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const email = (elements.namedItem('email') as HTMLInputElement).value;

      const captchaToken = await props.getRecaptchaToken();
      if (!captchaToken) return;

      await requestResetLink({ email, captchaToken });

      if (!errors.value.length) {
        (e.target as HTMLFormElement).reset();
        changeAuthAction('resetPasswordDone');
      }
    };

    return {
      changeAuthAction,
      errors,
      loading,
      onSubmit,
    };
  },
});
