import { Ref, watch } from 'vue';

export default function closeOnOutsideClick(isOpen: Ref<boolean>, elementId: string): void {
  const close = ({ target }: MouseEvent) => {
    const element = document.querySelector(elementId);
    const isOutsideClick = element !== target && !element?.contains(target as Node);

    if (isOpen.value && isOutsideClick) {
      isOpen.value = false;
    }
  };

  watch(isOpen, (elementOpen) => {
    if (elementOpen) {
      window.addEventListener('click', close);
    } else {
      window.removeEventListener('click', close);
    }
  });
}
