import { Ref } from 'vue';

import { ResponseError } from '@/lib/formatErrors';
import { setAuthToken, useRequest } from '@/lib/http';
import { AuthResponse } from './use-auth';
import { saveUserLocally } from './utils';

export const useResetPassword: UseResetPassword = () => {
  const { errors, loading, request } = useRequest();

  const resetPassword = async (payload: ResetPasswordPayload) => {
    const data = await request<AuthResponse>({
      url: `/auth/reset-password`,
      method: 'POST',
      data: payload,
    });

    if (data) {
      saveUserLocally(data.user, data.jwt);
      setAuthToken(data.jwt);
    }
  };

  return {
    resetPassword,
    loading,
    errors,
  };
};

export type UseResetPassword = () => {
  resetPassword(payload: ResetPasswordPayload): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type ResetPasswordPayload = {
  code: string;
  password: string;
  passwordConfirmation: string;
  captchaToken: string;
};
