<template>
  <PopupModal ref="popup" variant="coverScreen" :fixBackDrop="false" @on-close="clearSelections">
    <div class="p-4 sm:p-8 rounded-md bg-white max-h-screen overflow-y-auto overflow-hidden">
      <DisplayError
        v-show="clientErrors.length"
        :errors="clientErrors"
        errorkey="client.error"
        class="py-2 mb-2 text-center bg-gray-50 rounded-md"
      />

      <ProductSupplements
        v-if="product.supplements.length"
        :supplements="product.supplements"
        v-model:supplements="selectedSupplements"
      />

      <div
        class="pb-4 sm:pb-6 sm:min-w-[460px]"
        :class="{ 'py-4 sm:py-6 border-t': product.supplements.length }"
      >
        <h4 class="mb-6 text-xl font-display text-gray-700 sm:text-2xl">{{ product.name }}:</h4>

        <div
          class="space-y-2 sm:space-y-0 sm:flex sm:items-center"
          :class="[!product.variations ? 'sm:space-x-28' : 'sm:space-x-4 sm:justify-between']"
        >
          <div class="space-y-4">
            <span class="font-heading text-gray-600">Prix:</span>
            <p class="relative">
              <span
                v-if="product.variations && !size?.formattedPrice"
                class="w-20 absolute -top-3 text-xs text-gray-600"
              >
                A partir de
              </span>
              <span class="text-2xl md:text-3xl font-semibold">
                {{ size?.formattedPrice || getLowestPrice(product) }}
              </span>
            </p>
          </div>
          <div v-if="product.variations" class="space-y-4">
            <span class="font-heading text-gray-600">Taille:</span>
            <ProductSizeInput v-model:size="size" :sizes="product.variations.sizes" />
          </div>
          <div class="space-y-4">
            <span class="font-heading text-gray-600">Quantité:</span>
            <ProductQuantityInput v-model:quantity="quantity" />
          </div>
        </div>
      </div>

      <div
        class="
          pt-4
          border-t
          w-full
          text-center
          space-y-2
          sm:space-y-0 sm:space-x-2 sm:flex sm:items-center sm:justify-center
        "
      >
        <AppButton
          class="
            w-full
            border-2 border-transparent
            text-gray-800
            hover:border-gray-800
            focus:outline-none focus:border-gray-800
          "
          @click="clearSelections"
          :loading="loading"
        >
          Annuler
        </AppButton>
        <AppButton
          class="
            w-full
            border-2
            uppercase
            tracking-wider
            text-white
            bg-primary
            hover:bg-red-600
            focus:outline-none focus:bg-red-600
          "
          @click="onDone"
          :loading="loading"
        >
          Ajouter au Panier
        </AppButton>
      </div>
    </div>
  </PopupModal>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

import { Product, ProductSize, Supplement } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import PopupModal from '@/components/shared/PopupModal.vue';
import ProductSupplements from './ProductSupplements.vue';
import ProductQuantityInput from './ProductQuantityInput.vue';
import ProductSizeInput from './ProductSizeInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import getLowestPrice from '@/lib/getLowestPrice';
import useCart from '@/hooks/cart/use-cart';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    PopupModal,
    ProductSupplements,
    ProductQuantityInput,
    ProductSizeInput,
    AppButton,
    DisplayError,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const size = ref<ProductSize>();
    const quantity = ref(1);
    const selectedSupplements = ref<Supplement[]>([]);
    const clientErrors = ref<ResponseError[]>([]);
    const { showToast } = useToast();
    const { addCartProduct, loading, errors } = useCart().useAddCartProduct();

    const clearSelections = () => {
      size.value = undefined;
      quantity.value = 1;
      selectedSupplements.value = [];

      if (popup.value?.show) {
        popup.value?.close();
      }
    };

    const onDone = async () => {
      const newSize = size.value?.name
        ? size.value
        : {
            name: 'NORMALE',
            price: props.product.price,
            formattedPrice: props.product.formattedPrice,
          };

      if (props.product.variations && !size.value) {
        const message = 'Veuillez choisir une taille';
        clientErrors.value = [{ id: 'client.error.size', message }];
        return showToast({ message, type: 'failure' });
      }

      if (props.product.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
        return;
      }

      await addCartProduct({
        product: props.product,
        quantity: quantity.value,
        size: newSize,
        supplements: selectedSupplements.value,
      });

      if (!errors.value.length) {
        showToast({
          message: `${props.product.name} ajoutée au panier.`,
          type: 'link',
          link: '/checkout',
          linkText: 'Finaliser votre commande',
        });

        clientErrors.value = [];
        popup.value?.close();
      } else {
        clientErrors.value = [];
        errors.value.forEach(({ message }) => showToast({ message, type: 'failure' }));
      }
    };

    return {
      popup,
      size,
      quantity,
      selectedSupplements,
      loading,
      errors,
      clientErrors,
      onDone,
      getLowestPrice,
      clearSelections,
    };
  },
});
</script>
