import { Ref } from 'vue';

import { BusinessHours } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { getBusinessHoursState } from './use-business-hours';

export const useGetBusinessHours: UseGetBusinessHours = () => {
  const state = getBusinessHoursState();
  const { errors, loading, request } = useRequest();

  const getBusinessHours = async () => {
    if (state.businessHours.length || state.fetchingBusinessHours) return;

    state.fetchingBusinessHours = true;

    const data = await request<BusinessHours[]>({ url: `/business-hours` });

    if (data) {
      state.businessHours = data.sort((a, b) => {
        const aDay = a.day.toLowerCase();
        const bDay = b.day.toLowerCase();

        return daysMap[aDay] - daysMap[bDay];
      });
    }

    state.fetchingBusinessHours = false;
  };

  return { errors, loading, getBusinessHours };
};

export type UseGetBusinessHours = () => {
  getBusinessHours(): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

const daysMap: Record<string, number> = {
  lundi: 1,
  mardi: 2,
  mercredi: 3,
  jeudi: 4,
  vendredi: 5,
  samedi: 6,
  dimanche: 7,
};
