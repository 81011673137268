import { Ref } from 'vue';

import { Category } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { createCategory, getCategoriesState } from './use-categories';

export const useGetCategories: UseGetCategories = () => {
  const state = getCategoriesState();
  const { errors, loading, request } = useRequest();

  const getCategories = async () => {
    if (state.categories.length || state.fetchingCategories) return;

    state.fetchingCategories = true;

    const data = await request<Category[]>({ url: `/categories` });

    if (data) {
      state.categories = [
        createCategory('Tous les produits', 'all', data.length),
        createCategory('Formules', 'offers', data.length + 1),
        ...data,
      ];
    }

    state.fetchingCategories = false;
  };

  return { loading, errors, getCategories };
};

export type UseGetCategories = () => {
  getCategories(): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
