<template>
  <button class="relative" @click.stop="openCart">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 text-gray-600 transition-colors hover:text-gray-800"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-label="Votre Panier"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
      />
    </svg>
    <transition name="pop">
      <span
        class="
          w-4
          h-4
          rounded-full
          text-xs
          absolute
          -right-1
          -bottom-2
          flex
          items-center
          justify-center
          bg-primary
          text-white
        "
        :key="count"
      >
        {{ count }}
      </span>
    </transition>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useCart from '@/hooks/cart/use-cart';

export default defineComponent({
  setup() {
    const { openCart, count } = useCart();

    return {
      openCart,
      count,
    };
  },
});
</script>

<style scoped>
.pop-enter-active,
.pop-leave-active {
  transition: all 0.25s ease-in;
}

.pop-enter-from,
.pop-leave-to {
  transform: scale(1.25);
}
</style>
