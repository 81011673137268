<template>
  <header class="h-24">
    <nav class="min-w-full relative font-display bg-white header--fixed" id="header">
      <div class="max-w-screen-2xl mx-auto px-6 py-4 flex items-center bg-white md:px-4 lg:px-6">
        <div class="w-2/12 h-16 lg:w-1/6">
          <router-link class="inline-block w-16 h-full" to="/">
            <img
              src="@/assets/images/logo.png"
              width="80"
              height="80"
              alt="logo"
              class="w-full h-full object-fill"
            />
          </router-link>
        </div>
        <div class="w-6/12 flex items-center space-x-6 sm:space-x-0 md:space-x-6 lg:w-3/6">
          <div class="hidden md:flex md:space-x-6">
            <NavLink
              class="text-sm lg:text-base"
              v-for="link in links"
              :key="link.href"
              :href="link.href"
              :text="link.text"
            />
          </div>
          <BusinessHoursLink class="text-sm lg:text-base" />
        </div>
        <div class="w-4/12 sm:w-4/12 lg:w-2/6">
          <Skeleton :show="loadingUser">
            <div class="hidden md:flex md:justify-end md:items-center md:space-x-6">
              <CartIcon />
              <router-link :to="{ name: 'Wishlist' }" class="group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-gray-500 transition-colors group-hover:text-primary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-label="Liste de souhaits"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
              </router-link>
              <router-link v-if="isSignedIn" to="/account">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-gray-600 transition-colors hover:text-gray-800"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-label="Compte"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </router-link>
              <BorderButton
                v-if="!isSignedIn"
                @click="openAuthModal('signIn')"
                class="hover:bg-primary hover:text-white hover:border-white"
              >
                Se connecter
              </BorderButton>
              <BorderButton
                v-else
                class="hidden hover:bg-primary hover:text-white hover:border-white lg:block"
                @click="signOutUser"
              >
                Se déconnecter
              </BorderButton>
            </div>
          </Skeleton>

          <div class="flex items-center justify-end space-x-6 md:hidden">
            <CartIcon />

            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-label="Ouvrir le menu de navigation"
                @click="open = !open"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>
    <div
      class="
        h-screen
        fixed
        z-30
        inset-0
        transition-transform
        duration-500
        transform
        bg-gray-50
        md:hidden
      "
      :class="[open ? 'translate-x-0' : 'translate-x-full']"
    >
      <div class="p-6 flex justify-between bg-gray-white">
        <div class="flex items-center space-x-4">
          <div class="w-9 h-9" @click="open = false">
            <router-link to="/">
              <img src="@/assets/images/logo.png" class="w-full h-full" alt="logo" />
            </router-link>
          </div>
          <span class="text-gray-500 font-body">Miam Pizza</span>
        </div>
        <button @click="open = false" class="w-8 h-8">
          <ArrowCircleRightIcon class="w-full h-full text-gray-500" />
        </button>
      </div>
      <div class="mt-4 px-4 space-y-4 flex flex-col justify-center">
        <SidebarLink
          v-for="link in links"
          :key="link.href"
          :href="link.href"
          :text="link.text"
          :icon="link.icon"
          @click.stop="open = false"
        />
        <SidebarLink v-if="isSignedIn" href="/account" text="Compte" @click.stop="open = false">
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-label="Compte"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </template>
        </SidebarLink>
        <SidebarLink href="/wishlist" text="Liste de souhaits" @click="open = false">
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 transition-colors group-hover:text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-label="Liste de souhaits"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
          </template>
        </SidebarLink>
      </div>

      <div class="px-4 mt-8 flex space-x-4">
        <BorderButton
          v-if="!isSignedIn"
          @click="openAuthModal('signIn')"
          class="hover:bg-primary hover:text-white hover:border-white"
          >Se connecter</BorderButton
        >
        <BorderButton
          v-if="!isSignedIn"
          @click="openAuthModal('signUp')"
          class="hover:bg-primary hover:text-white hover:border-white"
          >S'inscrire</BorderButton
        >
        <BorderButton
          v-if="isSignedIn"
          @click="signOutUser"
          class="hover:bg-primary hover:text-white hover:border-white"
          >Se déconnecter</BorderButton
        >
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { ArrowCircleRightIcon } from '@heroicons/vue/outline';
import Headroom from 'headroom.js';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import BorderButton from '@/components/shared/BorderButton.vue';
import SidebarLink from './SidebarLink.vue';
import NavLink from './NavLink.vue';
import CartIcon from '@/components/cart/CartIcon.vue';
import BusinessHoursLink from '@/components/shared/BusinessHoursLink.vue';
import useAuth from '@/hooks/auth/use-auth';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: {
    Skeleton,
    BorderButton,
    SidebarLink,
    NavLink,
    CartIcon,
    BusinessHoursLink,
    ArrowCircleRightIcon,
  },
  setup() {
    const state = reactive({
      open: false,
    });
    const { openAuthModal, isSignedIn, signOut } = useAuth();
    const { loadingUser } = useUser();

    const signOutUser = () => {
      signOut();
      window.location.replace('/');
    };

    onMounted(() => {
      const header = document.getElementById('header');

      if (!header) return;

      const options = { offset: header.clientHeight - 10, tolerance: 5 };
      const headroom = new Headroom(header, options);
      headroom.init();
    });

    return {
      ...toRefs(state),
      links: [
        {
          text: 'Accueil',
          href: '/',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
</svg>`,
        },
        {
          text: 'Choisir sa Pizza',
          href: '/boutique',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
</svg>`,
        },
        {
          text: 'Contact',
          href: '/contact',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20" />
</svg>`,
        },
      ],
      openAuthModal,
      loadingUser,
      isSignedIn,
      signOutUser,
    };
  },
});
</script>

<style scoped>
.header--fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.headroom {
  will-change: transform;
  transition: transform 250ms linear;
}

.headroom--pinned {
  transform: translateY(0%);
  z-index: 30;
}

.headroom--unpinned {
  transform: translateY(-100%);
  z-index: 30;
}
</style>
