import { Ref } from 'vue';

import { CartItem, Product, ProductSize, Supplement } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { saveCartItem } from './utils';
import { getCartState } from './use-cart';
import useAuth from '@/hooks/auth/use-auth';

const { isSignedIn } = useAuth();

const useAddCartProduct: UseAddCartProduct = () => {
  const state = getCartState();
  const { errors, loading, request } = useRequest({ authClient: true });

  const localAddCartProduct = (payload: CartProductPayload) => {
    const cartItem: CartItem = {
      id: state.items[0]?.id + 1 || 1,
      product: payload.product,
      quantity: payload.quantity,
      size: payload.size,
      supplements: payload.supplements || [],
    };

    saveCartItem(cartItem, { formatItem: false, setSync: true });
  };

  const remoteAddCartProduct = async (payload: CartProductPayload) => {
    const data = await request<CartItem>({
      url: `/cart-items`,
      method: 'POST',
      data: {
        cartType: 'product',
        productId: payload.product.id,
        supplements: payload.supplements,
        quantity: payload.quantity,
        size: payload.size.name,
      },
    });

    if (data) {
      // We do not get the products supplements back
      // from the server but we know the product has
      // these supplements so just add them back.
      if (data.product && !data.product?.supplements) {
        data.product.supplements = payload.product?.supplements || [];
      }

      saveCartItem(data);
    }
  };

  const addCartProduct = async (payload: CartProductPayload) => {
    if (isSignedIn.value) {
      await remoteAddCartProduct(payload);
    } else {
      localAddCartProduct(payload);
    }
  };

  return { loading, errors, addCartProduct };
};

export default useAddCartProduct;

export type UseAddCartProduct = () => {
  addCartProduct(item: CartProductPayload): Promise<void>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};

type CartProductPayload = {
  product: Product;
  quantity: number;
  supplements: Supplement[];
  size: ProductSize;
};
