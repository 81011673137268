<template>
  <PopupModal ref="popup" variant="coverScreen" :fixBackDrop="false" @on-close="clearSelections">
    <div class="p-4 sm:p-8 rounded-md bg-white max-h-screen overflow-y-auto overflow-hidden">
      <ProductSupplements :supplements="supplements" v-model:supplements="selectedSupplements" />

      <div
        class="
          pt-4
          border-t
          w-full
          text-center
          space-y-2
          sm:space-y-0 sm:space-x-2 sm:flex sm:items-center sm:justify-center
        "
      >
        <AppButton
          class="
            w-full
            border-2 border-transparent
            text-gray-800
            hover:border-gray-800
            focus:outline-none focus:border-gray-800
          "
          @click="clearSelections"
        >
          Annuler
        </AppButton>
        <AppButton
          class="
            w-full
            border-2
            uppercase
            tracking-wider
            text-white
            bg-primary
            hover:bg-red-600
            focus:outline-none focus:bg-red-600
          "
          @click="onDone"
        >
          Terminé
        </AppButton>
      </div>
    </div>
  </PopupModal>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

import { Supplement } from '@/types/models';
import PopupModal from '@/components/shared/PopupModal.vue';
import ProductSupplements from '@/components/product/ProductSupplements.vue';
import AppButton from '@/components/shared/AppButton.vue';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: {
    PopupModal,
    ProductSupplements,
    AppButton,
  },
  emits: ['supplements:add'],
  props: {
    supplements: {
      type: Object as PropType<Supplement[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const selectedSupplements = ref<Supplement[]>([]);
    const { showToast } = useToast();

    const clearSelections = () => {
      selectedSupplements.value = [];

      if (popup.value?.show) {
        popup.value?.close();
      }
    };

    const onDone = async () => {
      if (props.supplements.length && !selectedSupplements.value.length) {
        showToast({ message: `Veuillez d'abord sélectionner la base`, type: 'failure' });
      } else {
        emit('supplements:add', selectedSupplements.value);
        popup.value?.close();
      }
    };

    return {
      popup,
      selectedSupplements,
      onDone,
      clearSelections,
    };
  },
});
</script>
